import { createContext } from "react";
import { ISideWindowControl } from "../../common/types/skobaTypes";

// export const RefreshDataContext2 = createContext(() => {});
export const SideWindowContext = createContext<ISideWindowControl>({
    changeSideWindow: () => {},
    openSideWindow: () => {},
    closeSideWindow: () => {},
    closeHierarchy: () => {},
    openHierarchy: () => {},
    setSwitcherOptions: (has2D, has3D) => {},
});