import { data } from "jquery";
import EstateService from "../../services/EstateService";
import { IFloorDetailed } from "../../types/estateTypes";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import FormModal from "../../../categories/components/modals/FormModal";
import FloorForm from "../forms/FloorForm";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export interface ISpecificItemInfoProps{
    data : IFloorDetailed
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function FloorInfo(props : ISpecificItemInfoProps){
    const navigate = useNavigate();
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, props.refreshProps)
    
    
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={[{
            name : "Název", 
            property : "name",            
        },{
            name : "Budova", 
            property : "buildingName",
            onClick: (object) => navigate("/estate/buildings/" + object.buildingId)                
        },{
            name : "Plocha [m2]", 
            property : "area",                  
        },{
            name : "Počet prostorů", 
            property : "roomsCount",            
        },{
            name : "Plocha prostorů [m2]", 
            property : "roomsArea",            
        },{
            name : "Počet prvků", 
            property : "itemsCount",            
        }]}
        buttons={(object) => [
            <FormModal  
            key={1}                        
            title="Upravit"    
            buttonElement={<span>Upravit</span>}
            form={<FloorForm buttonText="Uložit změny" model={props.data}  
                    selectOptions={[{name : props.data.buildingName, id: props.data.buildingId}]} 
                    selectable={false}
                    onSubmit={EstateService.updateFloor}
                />}
            />,
            <DeleteModal key={2} buttonText="Smazat" itemName={data.name} 
                onConfirm={() => EstateService.deleteFloor(props.data).then(() => {navigate("/estate/floors")})}/>
        ]}
    />
}