import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import SubscriptionService from '../../admin/services/SubscriptionService';
import dayjs from 'dayjs';
import AuthService from '../../auth/services/AuthService';
import TokenService from '../../auth/services/TokenService';

export default function SuccessPage() {
	const [searchParams] = useSearchParams();
	const plan = searchParams.get('plan');
	const frequency = searchParams.get('frequency');
	const timestamp = searchParams.get('timestamp');
	useEffect(() => {		
		if(plan && frequency && timestamp){
			SubscriptionService.createAndPaySubscription({
				type: parseInt(frequency), 
				invoceNumber: (Math.random() * 1000000).toFixed(0).toString(),
				amount: 0, 
				time: dayjs.unix(parseInt(timestamp)/1000).format("YYYY-MM-DDTHH:mm:ss[Z]").toString()})
				.then(() => {
					AuthService.getUserInfo().then(
						(result) => {							
      						TokenService.setUser(result)
						},
						(error) => {
						// 	setLoaded(true);
						// setError(error);
						})
				})
			// .then(AuthService.getUserInfo().then(userData) => ())
		}
	}, [])  
	return (
		<Container component="main" maxWidth="xs">
		<CssBaseline />
			<Box
				sx={{
				marginTop: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				}}
			>
				
			<Typography component="h1" variant="h5" color={"green"}>
				Platba úspěšně provedena
			</Typography>
			<NavLink to="/admin">Zpčt do aplikace</NavLink>  
			</Box>
		</Container>
	);
}