import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CategoryType, ISelect, ISelectBetter } from "../../../common/types/skobaTypes";
import { IItem, IItemDetailed, IItemType } from "../../types/itemTypes";
import { RefreshDataContext } from "../../../common/contexts/RefreshDataContext";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../../../common/components/LoadingWrapper";
import { SideWindowContext } from "../../../estate/contexts/SideWindowContext";
import ItemService from "../../services/ItemService";
import ItemsTable from "../tables/ItemsTable";
import ItemFilter from "../filters/ItemFilter";
import FormModal from "../../../categories/components/modals/FormModal";
import ItemForm from "../forms/ItemForm";
import ItemsSummary from "../tables/ItemsSummary";
import PrintableWrapper from "../../../common/components/PrintableWrapper";

export interface IItemsHierarchyProps{
    location?: ISelectBetter;
    getData : (category: CategoryType, subCategory: CategoryType) => Promise<IItem[]>;
}

export default function ItemsHierarchy(props: IItemsHierarchyProps){
    const { closeSideWindow, openHierarchy} = useContext(SideWindowContext);
    const navigate = useNavigate();
    const [data, setData] = useState<IItem[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [types, setTypes] = useState<IItemType[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [error, setError] = useState(false);
    const [errorObject, setErrorObject] = useState<any>(null);
    const [errorFilters, setErrorFilters] = useState<any>(null);
    const [typeFilter, setTypeFilter] = useState<number>(0);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [parentTypeFilter, setParentTypeFilter] = useState<number>(0);

    useEffect(() => {
        loadData();         
        openHierarchy();
        closeSideWindow();
    }, [])
    useEffect(() => {
        loadData()
    }, [typeFilter, parentTypeFilter])

    function loadData(){
        const typesPromise = ItemService.getTypes();
        const dataPromise = props.getData(parentTypeFilter, typeFilter);
        Promise.all([typesPromise, dataPromise])
        .then(
            ([data1, data2]) => {
                setLoaded(true);
                setLoadedData(true);
                setData(data2);
                setTypes(data1);
            },
            (error) => {
                setLoaded(true);
                setLoadedData(true);
                setError(true);
                setErrorObject(error);
                setErrorFilters(error);
            }
        )
    }
    function onFilterChange(data: any[]){
        setFilteredData(data);
    }   
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={error} loading={!loadedData}>
            <Box sx={{p:0, '& > *': { mb: 2 }}}>
                <ItemFilter data={data} 
                    onChangeCategory={(data:number) => setParentTypeFilter(data)}
                    onChangeSubCategory={(data:number) => setTypeFilter(data)}
                    onChangeName={(data:string) => setNameFilter(data)}
                    />     
                <PrintableWrapper>                    
                    <ItemsTable data={data.filter(x => x.name.includes(nameFilter))}/>                        
                    <ItemsSummary data={data.filter(x => x.name.includes(nameFilter))}/>
                </PrintableWrapper>
               {props.location && <FormModal 
                        buttonText="Vytvořit prvek"
                        title="Vytvořit nový prvek"    
                        form={<ItemForm buttonText="Vytvořit" location={props.location} onSubmit={ItemService.createItem}/>}
                        />}
            </Box>            
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }