import FileCategoriesPage from "../../files/pages/FileCategoriesPage"
import ItemPage from "./ItemPage"
import ItemsPage from "./ItemsPage"

const ItemsIndex = [
    {
        path: "",
        element: <ItemsPage/>
    },
    {
        path: ":id",
        element: <ItemPage/>
    },
    {
        path: "categories",
        element: <FileCategoriesPage/>
    },
]

export default ItemsIndex