import { IChartComptele } from "../../estate/types/chartTypes";
import React from "react";
import DonutChart from "./DonutChart";
import { Box } from "@mui/material";
import { IProcess, IProcessDetailed } from "../../processes/types/processTypes";
import TimelineChart from "./TimelineChart";
import { ICategory } from "../../categories/types/categoryTypes";
import { IItemCategory } from "../../items/types/itemTypes";
import { CategoryType } from "../types/skobaTypes";


export interface IChartManagerMethods {
    changeData: (data : IProcess, chartType: number) => void;
}
export interface IChartManagerState{
    chartType: number;
    data: IProcessDetailed[];
    categories: IItemCategory[];
    category: CategoryType;
}
export default class ChartManager extends React.Component<object, IChartManagerState>{
    constructor(props : object){
        super(props);
        this.state = ({
            chartType: -1,
            data: [],
            categories: [],
            category: CategoryType.Activity,
        })
    }
    getDonutCharts() : JSX.Element{
        const donutData1 = this.getDonutData1();
        const donutData2= this.getDonutData2();
        return <Box
        sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        }}
        >
            <DonutChart data={donutData1} title={"Nastavájící " + (this.state.category == CategoryType.Activity ? "činnosti" : "revize") + " dle kategorie"} left={true}/>
            <DonutChart data={donutData2} title={"Uzavřené " + (this.state.category == CategoryType.Activity ? "činnosti" : "revize") + " dle kategorie"}  left={false}/>
            {/* <DonutChart data={this.state.data[0].values} dataKey="value" title="Počet pocesy pro tento rok"/>
            <DonutChart data={this.state.data[1].values} dataKey="value" title="Počet pocesy pro přístí rok"/> */}
    </Box>
    }
    getTimelineChart() : JSX.Element{
        const timelineData = this.getTimelineData();
        return <Box
        sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <TimelineChart data={timelineData[0]} dataKeys={timelineData[1]} title={"Časová osa nastavajících " + (this.state.category == CategoryType.Activity ? "činností" : "revizí")}/>
    </Box>
    }

    getContent() : JSX.Element{
        return <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
            {this.state.chartType != 4 && this.getTimelineChart()}
            {this.getDonutCharts()}
        </Box>
    }
    changeType(type : number){
        this.setState({
            chartType: type,            
            data: this.state.data
        })
    }
    getTimelineData(){
        let startDate = new Date(); // Example start date
        const endDate = new Date();
        let chartData : any[] = [];
        let dataKeys = new Set<string>();
        switch(this.state.chartType){
            case 2:
                endDate.setDate(endDate.getDate() + 7);
                break;
            case 3:
                endDate.setMonth(endDate.getMonth() + 1);
                break;
            case 4:
        }
        while (startDate <= endDate) {
            let dataPoint : any = {
                date: startDate.toLocaleDateString("cs-CZ")
            };
            this.state.data.filter(x => new Date(Date.parse(x.startDate)).toDateString() == startDate.toDateString() && x.finished === false)
            .forEach(x => {
                dataKeys.add(x.name);
                dataPoint[x.name] = 1;
            })
            chartData.push(dataPoint)
            startDate.setDate(startDate.getDate() + 1);
        }
        return [chartData, Array.from(dataKeys)]
    }
    getDonutData1(){        
        let chartData : any[] = [];
        const today = new Date(Date.now())
        this.state.categories.forEach(x => {
            chartData.push({
                name: x.name,
                value: this.state.data.filter(y => y.categoryId === x.id && y.finished == false && new Date(y.startDate) <= today).length
            })
        })
        return chartData
    }
    getDonutData2(){        
        let chartData : any[] = [];
        const today = new Date(Date.now())
        this.state.categories.forEach(x => {
            chartData.push({
                name: x.name,
                value: this.state.data.filter(y => y.categoryId === x.id && y.finished == true).length
            })
        })
        return chartData
    }
    changeData(data : IProcessDetailed[], categories: IItemCategory[], chartType: number, category: CategoryType){
        this.setState({
            chartType: chartType,
            data: data,
            categories: categories,
            category: category
        })
    }
    render(): React.ReactNode{
        return this.getContent();
    }
    
}
