import { useNavigate } from "react-router-dom";
import { ISelect } from "../../../common/types/skobaTypes";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";

export interface IRoomsTableProps extends ISkobaTableDataProps{
    parentBuilding?: ISelect;
}
const RoomsHeader = ["Číslo", "Název", "Plocha [m2]", "Typ prostoru", "Areál", "Budova", "Patro", "Prvky"];

export default function RoomsTable(props : IRoomsTableProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={RoomsHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "number",
                    onClick: (object) => navigate("/estate/rooms/" + object.id)
                },{
                    property: "name"
                },{
                    property: "area"
                },{
                    property: "buildingPartName"
                },{
                    property: "premiseName",
                    onClick: (object) => navigate("/estate/premises/" + object.premiseId)                
                },{
                    property: "buildingName",
                    onClick: (object) => navigate("/estate/buildings/" + object.buildingId)                
                },{
                    property: "floorName",
                    onClick: (object) => navigate("/estate/floors/" + object.floorId)
                },{
                    property: "itemsCount"                
                }
                ],
                // buttons: (object) => [
                //     <FormModal 
                //         size="small"
                //         title="Upravit prostor"    
                //         buttonElement={<AiFillEdit/>}
                //         form={<RoomForm model={object} 
                //             buildingOptions={props.parentBuilding === undefined ? undefined : {name : props.parentBuilding!.name, id: props.parentBuilding!.id}} 
                //             buttonText="Uložit změny"
                //             selectable={true}
                //             onSubmit={HierarchyService.updateRoom}/>}
                //     /> ,
                //     <DeleteModal 
                //         size="small" 
                //         itemName={object.name} 
                //         onConfirm={() => HierarchyService.deleteRoom(object)}/>
                // ]
                
            }
        }
    />
}