import Sidebar, { LargeIconSize } from "../../common/components/Sidebar"
import STitle from "../../common/components/text/STitle"
import { useLocation } from "react-router-dom"
import { SkobaFilesIcon } from "../../common/components/icons/SkobaIcons"
import { SkobaColors } from "../../common/types/SkobaTheme"

const links = [
    {
        text: "Přehled",
        to: "/items",
        icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    },
    // {
    //     text: "Typy souborů",
    //     to: "/files/categories",
    //     icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    // }
]

export default function ItemsSidebar(){
    const location = useLocation();
    return <Sidebar
    header={<STitle text="" />}
    items={links}
    getActive={(item) => location.pathname == item.to}
    color={SkobaColors.Gray2}
    toggle
/>
}