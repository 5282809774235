import React from "react";
import { IMatterportDTO, SkobaEntityType } from "../../../common/types/skobaTypes";
import GeodataService from "../../services/GeodataService";
import LoadingWrapper from "../../../common/components/LoadingWrapper";
import { Box, Paper, Typography } from "@mui/material";

export interface IMatterportState{
    data: IMatterportDTO | undefined;
    error: boolean;
    errorMessage: string;
    loading: boolean;
}

class Matterport extends React.Component<object, IMatterportState>{
    constructor(props : object){
        super(props);
        this.state = ({
            data: undefined,
            error: false,
            errorMessage: "",
            loading: true
        })
    }
    loadData(id: number, type: SkobaEntityType){
        GeodataService.getMatterport(id, type).then(
            (result) => {
                this.setState({
                    data: result,
                    error: false,
                    errorMessage: "",
                    loading: false
                })
            },
            (error) => {
                
                this.setState({
                    data: undefined,
                    error: true,
                    errorMessage: error,
                    loading: false
                })
            }
        )
    }
    
    render(): React.ReactNode {      
        return <LoadingWrapper error={this.state.error} errorObject={this.state.errorMessage} loading={this.state.loading}>
        { this.state.data &&
            <React.Fragment>
                <Box sx={{
                    p: 0,
                    position: "absolute",
                    top: "0",
                    right: "0",
                    transform: "translate(0, 0)",
                    zIndex: 100,
                    display: "flex",
                    flexDirection: "column"
                    }}>                        
                        <Paper elevation={3} sx={{
                                paddingTop: 1,
                                paddingBottom: 1,
                                paddingX: 1,
                                display: 'flex',
                                flexDirection: 'row',  
                                gap: "5px",
                                backgroundColor: "#f2f2f2"
                            }}>
                                <Typography fontWeight="bold">
                                    Heslo: 
                                </Typography>
                                {this.state.data.password}
                        </Paper>                   
                </Box>
                <iframe width='100%' height='100%' src={this.state.data.url} allow='xr-spatial-tracking'></iframe>
            </React.Fragment>
        }
        </LoadingWrapper>    
    }
}
export default Matterport