import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SideWindowContext } from "../../estate/contexts/SideWindowContext";
import FilesHierarchy from "../../estate/components/content/FilesHierarchy";
import EstateService from "../../estate/services/EstateService";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import { IProcessDetailed } from "../types/processTypes";
import ProcessInfo from "../components/tables/ProcessInfo";
import FileService from "../../files/services/FileService";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import PrintableWrapper from "../../common/components/PrintableWrapper";

export default function ProcessPage(){
    const { closeSideWindow, openHierarchy, chartObj } = useContext(SideWindowContext);
    
    const [data, setData] = useState<IProcessDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        closeSideWindow();
    }, [])
    function loadData(){
        EstateService.getHierarchyData("Processes/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew 
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Process} 
                tabTitles={["Informace", "Soubory"]} >
                    <PrintableWrapper>                        
                        <ProcessInfo data={data}/>                         
                    </PrintableWrapper>
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Process, data.id)}
                        parent={{name: data.name, id: data.id, type: SkobaEntityType.Process}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }