import FileCategoriesPage from "./FileCategoriesPage";
import FilesPage from "./FilesPage";

const FilesIndex = [
    {
      path: "",
      element: <FilesPage/>
    }, 
    {
      path: ":id",
      element: <FilesPage/>
    },
    {
      path: "categories",
      element: <FileCategoriesPage/>
    },

]
export default FilesIndex;