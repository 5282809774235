// import "./Overview.css"
import OverviewSplitManager from "./OverviewSplitManager";
import SidebarLayout from "../../common/layouts/SidebarLayout";
import EstateSidebar from "../components/EstateSidebar";
import { SkobaColors } from "../../common/types/SkobaTheme";
import React, { ReactElement } from "react";

export interface ISplitScreenSidebarLayoutProps{
    sidebar: ReactElement
}

export default function SplitScreenSidebarLayout(props: ISplitScreenSidebarLayoutProps){    
    return <SidebarLayout    
            level={1}
            sideBarContent={props.sidebar}
            contentBackground={SkobaColors.Gray3}
        >
        <OverviewSplitManager refreshProp={false}/>
    </SidebarLayout>
    
}