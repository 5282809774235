import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../services/EstateService";
import { SideWindowType } from "../../layouts/OverviewSplitManager";
import { SideWindowContext } from "../../contexts/SideWindowContext";
import FormModal from "../../../categories/components/modals/FormModal";
import FloorForm from "../forms/FloorForm";
import { SkobaEntityType, ISelect } from "../../../common/types/skobaTypes";
import { IFloorDetailed } from "../../types/estateTypes";
import SingleTextFilter from "../../../common/components/filters/SingleTextFilter";
import FloorsTable from "../tables/FloorsTable";
import HierarchyFiltersMultipleNew from "../../../common/components/hierarchy/HierarchyFiltersMultipleNew";
import { prepareWFSLink } from "../maps/MapFunctions";
import LoadingWrapper from "../../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../../common/contexts/RefreshDataContext";
import FloorsSummary from "../tables/FloorsSummary";
import PrintableWrapper from "../../../common/components/PrintableWrapper";
import ReactToPrint from "react-to-print";


export interface IFloorsHierarchyProps{
    parent?: ISelect;
    showLocalMap?: boolean;
    showMatterport?: boolean
    getData : () => Promise<any[]>;
}

export default function FloorsHierarchy(props: IFloorsHierarchyProps){    
    const { changeSideWindow, openSideWindow, closeSideWindow, openHierarchy, localMap, matterport, setSwitcherOptions } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const componentRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<IFloorDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        openHierarchy();
    }, [])
    useEffect(() => {
        if(data !== undefined){
            setSwitcherOptions(props.showLocalMap === undefined ? false : props.showLocalMap, props.showMatterport === undefined ? false : props.showMatterport)
            if(props.showLocalMap){
                openSideWindow();
                changeSideWindow(SideWindowType.LocalMap);
            }
            else if(props.showMatterport){
                openSideWindow();
                changeSideWindow(SideWindowType.Matterport);
            }
        }
        if(props.showLocalMap === undefined && props.showMatterport === undefined){
            closeSideWindow();
        }
        
    }, [data])
    useEffect(() => {
        if(data !== undefined){
            setMapVectorData();
        }
        
    }, [data, localMap])
    useEffect(() => {
        if(data !== undefined){
            setMatterportData();
        }
        
    }, [data, matterport])
    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(props.parent && localMap){            
            localMap?.setWfsLayers(prepareWFSLink(props.parent?.id, SkobaEntityType.Building));            
        }
    } 
    function setMatterportData(): void{
        if(data && matterport && props.parent){          
            matterport?.loadData(props.parent?.id, SkobaEntityType.Building)
        }       
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            <PrintableWrapper>
                <HierarchyFiltersMultipleNew
                    data={data}
                    getChildrenTable={(data) => <FloorsTable data={data} parent={props.parent}/>}
                    getFilters={(data, onChange) => 
                        <SingleTextFilter 
                        data={data} 
                        onChange={onChange} 
                        filterProperty="name"
                        labelText="Hledat dle názvu patra"/> 
                    }
                    modifyButtons={props.parent ? [
                        <FormModal 
                            buttonText="Vytvořit patro"
                            title="Vytvořit nové patro"    
                            form={<FloorForm buttonText="Vytvořit" model={null} 
                                    selectOptions={[{name : props.parent!.name, id: props.parent!.id}]} 
                                    selectable={true}
                                    onSubmit={EstateService.createFloor}
                                />}
                        />  
                    ] : []}
                    />
                <FloorsSummary data={data}/>
            </PrintableWrapper>            
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }