import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthService from '../services/AuthService';
import { ISignup } from '../types/authTypes';
import { NavLink } from 'react-router-dom';
import SignUpForm from '../components/forms/SignUpForm';


export default function SignUpPage() {
  const [submit, setSubmit] = React.useState(false);    
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [out, setOut] = React.useState("");
  const handleSubmit = async (data : ISignup) => {
    setSubmit(true);
    setError(false);
    setErrorText("");
    await AuthService.signup(data)
      .then((res) =>{
        if(res.success === false){
          setError(true);
          setErrorText(res.error.messages.map((x : string) => x + "\n").join(""));
        }
        else{          
          setError(false);
          setErrorText("");
          setOut("Registrace proběhla v pořádku, nyní se můžete přihlásit");
        }
        setSubmit(false);
  }).catch(err => {    
    setError(true);
    setSubmit(false);
  })};
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > :not(style)': { mt: 1},
        }}
      >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
      Zaregistrovat se
      </Typography>      
      <SignUpForm onSubmit={handleSubmit} disabled={submit}/> 
        <NavLink to="/signin">Máte již účet? Přihlašte se</NavLink>  
      {(error && errorText !== "")&& 
        <Typography component="i" color="red">
          {errorText}
        </Typography>                  
      }  
      {(error && errorText === "") && 
        <Typography component="i" color="red">
          Při komunikaci s přihlašovacím serverem se stala chyba.
          Opakujte akci později.
        </Typography>                  
      }
      {out !== "" && 
        <Typography component="i" color="green">
          {out}
        </Typography>                  
      }
      </Box>

    </Container>
);
}