import { Box, Button } from "@mui/material";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";


export interface IPrintableWrapperProps{
	children: React.ReactNode;
};
export interface IPrintableWrapperProps{
	children: React.ReactNode;
};

const PrintableContent = React.forwardRef<HTMLDivElement, IPrintableWrapperProps>(
	(props, ref) => {
	return (
		<div ref={ref}>
		{props.children}
		</div>
	);
	}
);

export default function PrintableWrapper(props: IPrintableWrapperProps){
    const componentRef = useRef<HTMLDivElement>(null);
	return <React.Fragment>
		<PrintableContent ref={componentRef}>
			{props.children}
		</PrintableContent>	
		<Box sx={{pt:1}}>
			<ReactToPrint
					trigger={() => <Button color="primary" variant="contained">Tisk</Button>}
					content={() => componentRef.current}
			/>
		</Box>	
	</React.Fragment>

}