import { AiFillEdit } from "react-icons/ai";
import FormModal from "../../../categories/components/modals/FormModal";
import { downloadFile, formatFileSize, getParentLocationId, getParentName, getParentRoute, getParentType } from "../../../common/Functions";
import FileService from "../../services/FileService";
import FileForm from "../forms/FileForm";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";

const FilesHeader = ["Název", "Typ", "Popis", "Velikost", "Soubor", "Položka"];

export default function FilesTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={FilesHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name",
                    // onClick: (object) => navigate("/estate/files/" + object.id)
                },{
                    property: "categoryName"
                },{
                    property: "description"
                },{
                    property: "size",
                    getValue: (object) => formatFileSize(Number(object.size) * 1024)
                },{
                    property: "filename",
                    onClick: (object) => FileService.downloadFile(object.id).then(downloadFile)
                },{
                    property: "",
                    getValue: (object) => getParentName(object),
                    onClick: (object) => navigate(getParentRoute(object))
                }
                ],
                buttons: (object) => {
                    object.location = getParentLocationId(object);
                    object.locationType = getParentType(object);
                    return [
                    <FormModal 
                        buttonElement={<AiFillEdit/>}
                        title="Upravit soubor"      
                        size="small"                                           
                        form={<FileForm buttonText="Uložit změny" model={object}
                            onSubmit={FileService.updateFile}
                        />}
                    />,
                    <DeleteModal 
                        size="small" 
                        itemName={object.name} 
                        onConfirm={() => FileService.deleteFile(object)}/>
                ]}
                
            }
        }
    />
}