import { IUser } from "../types/adminTypes";
import ApiService from "../../common/services/ApiService";

const getUsers = () : Promise<IUser[]> => {
  return ApiService.apiGet("/users");
};
const updateUser = (user : IUser) => {
  return ApiService.apiPut("/users/" + user.id, user);   
};
const createUser = (user : IUser) => {
  return ApiService.apiPost("/users", user);    
};
const deleteUser = (id : string) => {
  return ApiService.apiDelete("/users/" + id);
};
const getOrganizationInfo = () => {  
  return ApiService.apiGet("/organizations/current"); 
}
const getLatestSubscription = ()  => {
  return ApiService.apiGet("/subscriptions/latest"); 
}
const getSubscriptions = ()  => {
  return ApiService.apiGet("/subscriptions");
}
const AdminService = {
    getUsers,
    updateUser,
    createUser,
    deleteUser,
    getOrganizationInfo,
    getLatestSubscription,
    getSubscriptions
};

export default AdminService;