import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from "@mui/material";
import SHeader from "../text/SHeader";
import PrintableWrapper from "../PrintableWrapper";

export interface IPropertiesInfoTableProps{
    data : any;
    rowDefinition: IPropertyInfoRowDefinition[];
    buttons? : (object : any) => JSX.Element[];
}

export interface IPropertyInfoRowDefinition{
    property : any;
    name: string;
    getValue? : (value : any) => any;
    onClick? : (object : any) => void;  
}
export default function PropertiesInfoTable(props : IPropertiesInfoTableProps){
    const theme = useTheme();
    return <Box>
            <TableContainer sx={{borderRadius: "0", overflow: "auto"}}>
                <Table sx={{ width:"auto"}} size="small">                
                    <TableBody>                    
                        {props.rowDefinition.map((row, ix) => {
                            const value = row.getValue ? row.getValue(props.data) : props.data[row.property];
                            return <TableRow key={ix} sx={{'& td': {border: "1px solid #aeaeae; "}}}>
                                <TableCell sx={{ 
                                    background:"#dcdcdc", 
                                    color: 'black'}} 
                                    align="left"><SHeader text={row.name}/></TableCell>
                                <TableCell align="center" sx={{p:"2px"}}></TableCell>
                                {(value === undefined || value === "" || value === null || Number.isNaN(value)) ? <TableCell align="center">-</TableCell>
                                : <TableCell align="center" onClick={() => row.onClick?.(props.data)} sx={row.onClick !== undefined ? {
                                    cursor: "pointer",
                                    color: theme.palette.primary.main
                                } : {}}>
                                    {value}
                                </TableCell>
                                }

                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        {props.buttons !== undefined && 
            <div className="no-print">
                <Stack direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{pt:1}}>
                    {props.buttons(props.data).map((button, ix) => {
                        return <Box key={ix}>{button}</Box>;
                    })}
                </Stack>
            </div>
        }
    </Box>
}