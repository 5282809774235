
import PaymentsPage from './PaymentsPage';
import InfoPage from './InfoPage';
import UsersPage from './UsersPage';

const AdminIndex = [
    {
      path: "",
      element: <InfoPage/>
    },
    {
      path: "users",
      element: <UsersPage/>
    },
    {
      path: "payments",
      element: <PaymentsPage/>
    },
]
export default AdminIndex