import { Dialog, DialogTitle, DialogContent, Box, List, ListItemButton, ListItemText, DialogActions, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ItemService from "../../services/ItemService";
import { IDefinition, IDefinitionDetailed } from "../../types/itemTypes";
import ItemFilterWindow from "./ItemFilterWindow";

export interface IItemFilterModalProps{
    categoryId: number;
    subCategoryId: number;
    name: string;
}

export default function ItemFilterModal(props: IItemFilterModalProps){    
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const [definitions, setDefinitions] = useState<IDefinitionDetailed[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        loadData();
    }, [])
    
    function loadData(){
        ItemService.getDefinitions().then(
            (result) => {
                setLoaded(true);
                setDefinitions(result);
            },
            (error) => {
                setError(true);
            }
        )
    }

    return <React.Fragment>
        <Button variant="contained" onClick={() => setOpen(true)}>
        Všechny parametry
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle sx={{bgcolor: "#e6e6e6"}}>Vyberte parametry na filtrování</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', p:0}}>
            <Box sx={{ 
                display: 'flex',
                flexGrow: 1,
                overflow: 'hidden'
            }}>
                <Box sx={{
                    width: "25%",
                    overflowY: "auto",                    
                    bgcolor: "#e6e6e6"
                }}>
                    <List sx={{pt:0}}>
                        {definitions.filter(x => 
                            (props.categoryId === 0 || props.categoryId === x.itemTypeParentTypeId) && 
                            (props.subCategoryId === 0 || props.subCategoryId === x.itemTypeId)).sort((a,b) => a.name > b.name ? 1 : -1).map((definition) => (
                            <ListItemButton 
                                key={definition.id}
                                onClick={() => setSelectedOption(definition.id)}
                                sx={{bgcolor: definition.id == selectedOption ? "white" : "inherit"}}
                            >
                                <ListItemText primary={definition.name}/>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
                <Box sx={{ width: "75%", padding: 2 }}>
                    <ItemFilterWindow definitionId={selectedOption} definitionName={definitions.find(x => x.id === selectedOption)?.name}/>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ([])}>Zrušit vybrané parametry</Button>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained">
            Zavřít
          </Button>
          <Button onClick={() => {}} color="primary" variant="contained">
            Zobrazit XXX položek
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
}