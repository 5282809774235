import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowType } from "../layouts/OverviewSplitManager";
import { SideWindowContext } from "../contexts/SideWindowContext";
import EstateService from "../services/EstateService";
import FloorsHierarchy from "../components/content/FloorsHierarchy";
import RoomsHierarchy from "../components/content/RoomsHierarchy";
import FilesHierarchy from "../components/content/FilesHierarchy";
import ProcessesHierarchy from "../components/content/ProcessesHierarchy";
import SensorsHierarchy from "../components/content/SensorsHierarchy";
import BuildingInfo from "../components/tables/BuildingInfo";
import { Feature } from "ol";
import { IVectorLayer, IVectorData } from "../components/maps/ControlMap";
import { getBuildingStyle } from "../components/maps/ControlMapStyles";
import { Point } from "ol/geom";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import { IBuildingDetailed } from "../types/estateTypes";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import ItemsHierarchy from "../../items/components/content/ItemsHierarchy";
import ItemService from "../../items/services/ItemService";
import FileService from "../../files/services/FileService";
import PrintableWrapper from "../../common/components/PrintableWrapper";


export default function BuildingPage(){    
    const { changeSideWindow, openSideWindow, openHierarchy, setSwitcherOptions, controlMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IBuildingDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        openSideWindow();
        changeSideWindow(SideWindowType.ControlMap);
    }, [])
    useEffect(() => {
        if(data !== undefined)
            setSwitcherOptions(data.hasDrawing, data.hasMatterport)
        zoomAndShow()
    }, [data])
    function loadData(){
        EstateService.getHierarchyData("Buildings/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function zoomAndShow() : void{        
        if(data && data.xDef && data.yDef){
            const vectorLayers = [] as IVectorLayer[];
            const features = [] as IVectorData[];
            features.push({
                feature: new Feature({
                    geometry: new Point([data.xDef as number,data.yDef as number]),
                    type: "Building",
                    name: data.name,
                    id: data.id

                }),
                onClick: () => navigate("/estate/buildings/" + data.id),
                onLeave: () => {console.log("leave")},
                onEnter: () => {console.log("enter")},
            } as IVectorData )      
            vectorLayers.push({layers: features, style: getBuildingStyle()});
            controlMap?.setVectorDataAndZoom(vectorLayers, {center: [data.xDef, data.yDef], zoom: 14});
            // controlMap?.zoomOnPoint({center: [data.xDef, data.yDef], zoom: 14})
        }
    }
    //TODO: replace all static urls
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}                
                id={data.id} 
                type={SkobaEntityType.Building} 
                tabTitles={["Informace", "Patra", "Prostory", "Čidla", "Prvky", "Revize", "Činnosti", "Soubory"]}>                    
                    <PrintableWrapper>
                        <BuildingInfo data={data!} onMount={() => {
                                changeSideWindow(SideWindowType.ControlMap);
                                openSideWindow();
                                zoomAndShow();
                            }}
                            onRefresh={() => {
                                zoomAndShow();
                            }}
                            refreshProps={[data, controlMap]}
                        />
                    </PrintableWrapper>                    
                    <FloorsHierarchy 
                        showLocalMap={data.hasDrawing}
                        showMatterport={data.hasMatterport}
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Building, data.id, SkobaEntityType.Floor)}/>
                    <RoomsHierarchy
                        parent={{id: data.id, name: data.name}}
                        showLocalMap={data.hasDrawing}
                        showMatterport={data.hasMatterport}
                        buildingOptions={{id: data.id, name: data.name}}
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Building, data.id, SkobaEntityType.Room)}/>
                    <SensorsHierarchy 
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Building, data.id, SkobaEntityType.Sensor)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Building}}
                        getData={async (category: CategoryType, subCategory: CategoryType) => ItemService.getAllOfParent(SkobaEntityType.Building, data.id, category, subCategory)}/>
                    <ProcessesHierarchy 
                        category={CategoryType.Revision}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Building}}/>
                    <ProcessesHierarchy 
                        category={CategoryType.Activity}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Building}}/>
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Building, data.id)}
                        parent={{name: data.name, id: data.id, type: 1}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>    
  }