import { AiFillEdit } from "react-icons/ai";
import ProcessService from "../../services/ProcessService";
import ProcessForm from "../forms/ProcessForm";
import { formatDatetime, getParentLocationId, getParentName, getParentRoute, getParentType } from "../../../common/Functions";
import FormModal from "../../../categories/components/modals/FormModal";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";
import { useNavigate } from "react-router-dom";
import ProcessesTable from "./ProcessesTable";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import DeleteProcessModal from "../../../categories/components/modals/DeleteProcessModal";
import { CategoryType } from "../../../common/types/skobaTypes";


const UnclosedProcessesTableHeader = ["Název", "Datum", "Kategorie", "Cena", "Předmet"];

export default function UnclosedProcessesTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    function getModalName(categoryType: CategoryType){
        if(categoryType === CategoryType.Revision){
            return "revizi"
        }
        if(categoryType === CategoryType.Activity)
            return "činnost"
        return "událost"
    }
    return <ProcessesTable
        data={props.data}
        buttons={(object) => {
            object.location = getParentLocationId(object);
            object.locationType = getParentType(object);
            return [
                <FormModal 
                    size="small" 
                    buttonElement={<AiFillEdit/>}
                    color="success"
                    title="Dokončit process"    
                    form={<ProcessForm buttonText="Dokončit" model={object} finishing={true}
                        category={object.categoryType}
                        onSubmit={ProcessService.finishProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                />,
                <FormModal 
                    size="small" 
                    buttonElement={<AiFillEdit/>}
                    title="Upravit process"    
                    form={<ProcessForm buttonText="Uložit změny" model={object} finishing={false}
                        category={object.categoryType}
                        onSubmit={ProcessService.updateProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                />,
                object.repeating ? 
                <DeleteProcessModal 
                    size="small" 
                    bodyText={object.name} 
                    onDeleteOne={() => ProcessService.deleteProcessRepeating(1, object.id, object.startDate)}
                    onDeleteAll={() => ProcessService.deleteProcessRepeating(3, object.id, object.startDate)}
                    onDeleteThisAndNext={() => ProcessService.deleteProcessRepeating(2, object.id, object.startDate)}/> 
                :
                <DeleteModal 
                    size="small" 
                    itemName={object.name}
                    onConfirm={() => ProcessService.deleteProcess(object)}/>
            ]        
        }}
    />
}