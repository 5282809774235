import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import STitle from "../components/text/STitle";

export interface IHierarchyMultipleFilterPageProps{
    title: string;    
}

export default function HierarchyMultipleFilterPageNew(props: PropsWithChildren<IHierarchyMultipleFilterPageProps>){
    return <React.Fragment>
        <Box sx={{p:1, overflowY: "auto", height:"100%", '& > *': { mb: 1 }}}>
            <STitle text={props.title}/>
            {props.children}            
            </Box>
    </React.Fragment>
  }