import FormModal from "../../../categories/components/modals/FormModal";
import { IProcessDetailed } from "../../types/processTypes";
import { ReactElement } from "react";
import ProcessService from "../../services/ProcessService";
import ProcessForm from "../forms/ProcessForm";
import { formatDatetime, getParentLocationId, getParentName, getParentRoute, getParentType } from "../../../common/Functions";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import DeleteProcessModal from "../../../categories/components/modals/DeleteProcessModal";

export interface ISpecificItemInfoProps{
    data : IProcessDetailed
}

export default function ProcessInfo(props : ISpecificItemInfoProps){
    const navigate = useNavigate();
    const today = new Date(Date.now())
    function getButtons() : JSX.Element[]{
        const buttons = getEditButtons();
        buttons.push(getDeleteButton());
        return buttons;
    }
    function getEditButtons() : React.ReactElement[]{        
        props.data.location = getParentLocationId(props.data);
        props.data.locationType = getParentType(props.data);
        if(new Date(props.data.startDate) > today && !props.data.finished)
            return [<FormModal 
                    key={1}
            buttonElement={<span>Upravit</span>}
                title="Upravit process"                                                
                form={<ProcessForm buttonText="Uložit změny" model={props.data} finishing={false}
                    category={props.data.categoryType}
                    onSubmit={ProcessService.updateProcess} 
                    onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
            />]
        else if(new Date(props.data.startDate) <= today && !props.data.finished)
            return [
                <FormModal  
                    key={1}
                    buttonElement={<span>Upravit</span>}
                    title="Upravit process"    
                    form={<ProcessForm buttonText="Uložit změny" model={props.data} finishing={false}
                        category={props.data.categoryType}
                        onSubmit={ProcessService.updateProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                />,
                <FormModal                     
                    key={2} 
                    buttonElement={<span>Dokončit</span>}
                    title="Dokončit process"    
                    form={<ProcessForm buttonText="Dokončit" model={props.data} finishing={true}
                        category={props.data.categoryType}    
                        onSubmit={ProcessService.finishProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                    />
                ]
        else
            return [
                <FormModal                     
                    key={1}
                    buttonElement={<span>Upravit</span>}
                    title="Upravit process"    
                    form={<ProcessForm buttonText="Uložit změny" model={props.data} finishing={true}
                        category={props.data.categoryType}
                        onSubmit={ProcessService.updateProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                />]
    }
    function getDeleteButton() : ReactElement{
        return props.data.repeating ? <DeleteProcessModal 
            bodyText={props.data.name} 
            buttonText="Smazat"
            onDeleteOne={() => ProcessService.deleteProcessRepeating(1, props.data.id, props.data.startDate)
                .then(() => navigate("/estate/processes"))}
            onDeleteAll={() => ProcessService.deleteProcessRepeating(3, props.data.id, props.data.startDate)
                .then(() => navigate("/estate/processes"))}
            onDeleteThisAndNext={() => ProcessService.deleteProcessRepeating(2, props.data.id, props.data.startDate)
                .then(() => navigate("/estate/processes"))}/> 
                // .then(() => props.onHierarchyChange(getParentLocationType(props.data) + 1, getParentLocationId(props.data)))}/> 
        :
        <DeleteModal buttonText="Smazat" itemName={props.data.name} onConfirm={() => ProcessService.deleteProcess(props.data)
            .then(() => {navigate("/estate/processes")})}/>
    }
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={[{
            name : "Název", 
            property : "name",            
        },{
            name : "Datum", 
            property : "startDate",
            getValue: (object) => formatDatetime(object.startDate)       
        },{
            name : "Kategorie", 
            property : "categoryName",            
        },{
            name : "Cena", 
            property : "cost",  
        },{
            name : "Ukončený", 
            property : "finished",            
            getValue: (object) => object.finished ? "Ano" : "Ne"       
        },{
            name : "Položka", 
            property : "",
            getValue: (object) => getParentName(object),
            onClick: (object) => navigate(getParentRoute(object))          
        }]}
        buttons={(object) => getButtons()}
    />
}