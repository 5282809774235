import React, { useContext } from "react";
import EstateService from "../../estate/services/EstateService";
import ProcessesHierarchy from "../../estate/components/content/ProcessesHierarchy";
import { Box } from "@mui/material";
import STitle from "../../common/components/text/STitle";
import { CategoryType } from "../../common/types/skobaTypes";
import ProcessService from "../services/ProcessService";

export interface IProcessesPageProps{
    categoryType : CategoryType;
}

export default function ProcessesPage(props: IProcessesPageProps){    
    function getText(){
        if(props.categoryType === CategoryType.Activity)
            return "Činnosti"
        if(props.categoryType === CategoryType.Revision)
            return "Revize"
        return "Přehled"
    }
    return <React.Fragment>
        <Box sx={{p:2, overflowY: "auto", height:"100%", '& > *': { mb: 2 }}}>
            <Box sx={{mb:2}}>
                <STitle text={getText()}/>
            </Box>
                <ProcessesHierarchy category={props.categoryType} />
        </Box>
    </React.Fragment>
    
  }