import { Box } from "@mui/material";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";
import AdminService from "../../services/AdminService";
import UserForm from "../forms/UserForm";
import FormModal from "../../../categories/components/modals/FormModal";

export interface IUsersInfoProps{
    data : IUserStatistics
}
export interface IUserStatistics{
    count: number;
    owners: number;
    admins: number;
    editors: number;
    users: number;
}

export default function UsersInfo(props : IUsersInfoProps){    
    return <Box sx={{'& > *': { mb: 2 }}}>
            <PropertiesInfoTable
            data={props.data}
            rowDefinition={[{
                name : "Celkový počet uživatelů", 
                property : "count",            
            },{
                name : "Počet vlastníků", 
                property : "owners",             
            },{
                name : "Počet administrátorů", 
                property : "admins",                  
            },{
                name : "Počet editorů", 
                property : "editors",            
            },{
                name : "Počet běžných uživatelů", 
                property : "users",            
            }]}
            />
            <FormModal                
                title="Vytvořit nového uživatele"    
                buttonText="Vytvořit uživatele"
                form={<UserForm buttonText={"Uložit změny"} onSubmit={AdminService.createUser}/>}
            /> 
    </Box>
}