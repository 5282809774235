import { data } from "jquery";
import EstateService from "../../services/EstateService";
import { IRoomDetailed } from "../../types/estateTypes";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import FormModal from "../../../categories/components/modals/FormModal";
import RoomForm from "../forms/RoomForm";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ISelect } from "../../../common/types/skobaTypes";

export interface ISpecificItemInfoProps{
    data : IRoomDetailed
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function RoomInfo(props : ISpecificItemInfoProps){
    const navigate = useNavigate();
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, props.refreshProps)
    
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={[{
            name : "Číslo", 
            property : "number",            
        },{
            name : "Název", 
            property : "name",            
        },{
            name : "Areál", 
            property : "premiseName",
            onClick: (object) => navigate("/estate/premises/" + object.premiseId)                
        },{
            name : "Budova", 
            property : "buildingName",
            onClick: (object) => navigate("/estate/buildings/" + object.buildingId)                
        },{
            name : "Patro", 
            property : "floorName",
            onClick: (object) => navigate("/estate/floors/" + object.floorId)
        },{
            name : "Plocha [m2]", 
            property : "area",                  
        },{
            name : "Počet prvků", 
            property : "itemsCount",            
        }]}
        buttons={(object) => [
            <FormModal                     
                key={1}
                title="Upravit prostor"
                buttonElement={<span>Upravit</span>}
                form={<RoomForm model={props.data} 
                    selectable={false}
                    premiseOption={props.data.premiseId !== null ? {id: props.data.premiseId, name: props.data.premiseName} as ISelect : undefined}
                    buildingOptions={props.data.buildingId !== null ? {id: props.data.buildingId, name: props.data.buildingName} as ISelect : undefined}
                    floorOptions={props.data.floorId !== null ? {id: props.data.floorId, name: props.data.floorName} as ISelect : undefined}
                    buttonText="Uložit změny"                        
                    onSubmit={EstateService.updateRoom}/>}
            />,
            <DeleteModal key={2} buttonText="Smazat" itemName={data.name} 
            onConfirm={() => EstateService.deleteRoom(props.data)
                .then(() => {navigate("/estate/rooms")})}/>
        ]}
    />    
}