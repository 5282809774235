import ApiService from "../../common/services/ApiService";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import { IDefinition, IItem, IPropertyValue, IItemProperty, IItemType, IDefinitionDetailed, IItemCreate, IItemDetailed, IItemPropertyForFilter } from "../types/itemTypes";

const getDefinitions = () : Promise<IDefinitionDetailed[]>=> {
    return ApiService.apiGet("/Definitions");
};
const getDefinition = (id : any) : Promise<IDefinition>=> {
    return ApiService.apiGet("/Definitions/" + id);
};
const getDefinitionProperties = (id : any) : Promise<IItemProperty[]>=> {
    return ApiService.apiGet("/Definitions/" + id + "/getProperties");
};
const getDefinitionPropertiesNonShared = (id : any) : Promise<IItemPropertyForFilter[]>=> {
    return ApiService.apiGet("/Definitions/" + id + "/getPropertiesNonShared");
};
const getItems = (categoryType?: number, subCategoryType?: number) : Promise<IItem[]>=> {
    if(categoryType && subCategoryType)
        return ApiService.apiGet("/Items?categoryId=" + categoryType + "&subCategoryId=" + subCategoryType);
    if(subCategoryType)
        return ApiService.apiGet("/Items?subCategoryId=" + subCategoryType);
    if(categoryType)
        return ApiService.apiGet("/Items?categoryId=" + categoryType);
    return ApiService.apiGet("/Items");
};
const getAllOfParent = (parentType: SkobaEntityType, parentId: number, categoryType?: number, subCategoryType?: number) : Promise<IItem[]>=> {
    if(categoryType && subCategoryType)
        return ApiService.apiGet("/Items/allOfParent?type=" + parentType + "&id=" + parentId + "&categoryId=" + categoryType + "&subCategoryId=" + subCategoryType);
    if(subCategoryType)
        return ApiService.apiGet("/Items/allOfParent?type=" + parentType + "&id=" + parentId + "&subCategoryId=" + subCategoryType);
    if(categoryType)
        return ApiService.apiGet("/Items/allOfParent?type=" + parentType + "&id=" + parentId + "&categoryId=" + categoryType);

    return ApiService.apiGet("/Items/allOfParent?type=" + parentType +"&id=" + parentId);
};
const getItem = (id : any) : Promise<IItemDetailed> => {
    return ApiService.apiGet("/Items/" + id);
};
const createItem = (item: IItemCreate) : Promise<IItem> => {
    return ApiService.apiPost("/Items", item);
};
const updateItem = (item: IItemDetailed) : Promise<void> => {
    return ApiService.apiPut("/Items/" + item.id, item);
};
const deleteItem = (item: IItem) : Promise<void> => {
    return ApiService.apiDelete("/Items/" + item.id);
};
const getItemValues = (id : any) : Promise<IPropertyValue[]>=> {
    return ApiService.apiGet("/Items/" + id + "/values");
};

const getItemProperties = (id : any) : Promise<IItemProperty[]>=> {
    return ApiService.apiGet("/Items/" + id + "/properties");
};

const getTypes = () : Promise<IItemType[]>=> {
    return ApiService.apiGet("/ItemTypes/All");
};

const ItemService = {
    getDefinitions,
    getDefinition,
    getDefinitionProperties,
    getItems,
    getAllOfParent,
    getItem,
    createItem,
    updateItem,
    deleteItem,
    getItemValues,
    getItemProperties,
    getTypes,
    getDefinitionPropertiesNonShared,
};

export default ItemService;