import { ReactElement, useState } from "react";
import React from "react";
import { Box, Stack } from "@mui/material";

export interface IHierarchyMultipleProps{    
    data: any[];
    getChildrenTable : (data : any[]) => ReactElement;
    getFilters : (data : any[], onChange : (data: any[]) => void) => ReactElement;
    modifyButtons : ReactElement[];
    summary? : (data : any[]) => ReactElement;
}

export default function HierarchyFiltersMultipleNew(props : IHierarchyMultipleProps) {
    const [filteredData, setFilteredData] = useState<any[]>([]);
    
    function FilterData(data : any[]){
        setFilteredData(data);
    }
    function getModifyButtons() : ReactElement{
        return <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{pt:1}}
            >
                {props.modifyButtons.map((button, i) => 
                <React.Fragment key={i}>
                    {button}
                </React.Fragment>)}
        </Stack>
    }
    return <Box sx={{p:0, '& > *': { mb: 2 }}}>
        <div className="no-print">
            {props.getFilters(props.data, FilterData)}

        </div>
            {props.getChildrenTable(filteredData)}
            <div className="no-print">
            {getModifyButtons()}
        </div>
    </Box>
}