import { AiFillEdit } from "react-icons/ai";
import CategoryForm from "../forms/CategoryForm";
import { CategoryType, SkobaEntityType } from "../../../common/types/skobaTypes";
import CategoryService from "../../services/CategoryService";
import DeleteModal from "../modals/DeleteModal";
import FormModal from "../modals/FormModal";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";

export interface IGenericCategoryTableProps extends ISkobaTableDataProps{
    updateText: string
    type: CategoryType
}

const GenericCategoryHeader = ["Název"];

export default function GenericCategoryTable(props : IGenericCategoryTableProps ){
    return <SkobaTable
        data={props.data}
        header={GenericCategoryHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name"
                }
                ],
                buttons: (object) => [
                    <FormModal
                        size="small"
                        title="Upravit kategorii"
                        buttonElement={<AiFillEdit/>}
                        form={<CategoryForm 
                            buttonText="Uložit změny" 
                            model={object} 
                            type={props.type} 
                            onSubmit={CategoryService.updateCategory}/>}
                    /> ,
                    <DeleteModal itemName={object.name} 
                    onConfirm={() => CategoryService.deleteCategory(object)}/>
                ]                
            }
        }
    />
}