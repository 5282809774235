import { useNavigate } from "react-router-dom";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";
import { AiFillEdit } from "react-icons/ai";
import ProcessService from "../../services/ProcessService";
import FormModal from "../../../categories/components/modals/FormModal";
import { formatDatetime, getParentName, getParentRoute, getParentLocationId, getParentType } from "../../../common/Functions";
import ProcessForm from "../forms/ProcessForm";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import DeleteProcessModal from "../../../categories/components/modals/DeleteProcessModal";

const ProcessesTableHeader = ["Název", "Datum", "Kategorie", "Cena", "Předmet"];

export interface IProcessTableProps extends ISkobaTableDataProps{
    buttons? : (object : any) => JSX.Element[];
}

export default function ProcessesTable(props : IProcessTableProps){
    const navigate = useNavigate();
    return <SkobaTable
        data={props.data}
        header={ProcessesTableHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name",
                    onClick: (object) => navigate(("/processes/" + object.id))
                },{
                    property: "startDate",
                    getValue: (object) => formatDatetime(object.startDate)
                },{
                    property: "categoryName"
                },{
                    property: "cost"
                },{
                    property: "",
                    getValue: (object) => getParentName(object),
                    onClick: (object) => navigate(getParentRoute(object))
                }
                ],
                buttons: (object) => {                    
                    object.location = getParentLocationId(object);
                    object.locationType = getParentType(object);
                    if(props.buttons){
                        return props.buttons(object);
                    }
                    return [
                        <FormModal 
                            buttonElement={<AiFillEdit/>}
                            title="Upravit process"    
                            form={<ProcessForm buttonText="Uložit změny" model={object} finishing={true}
                                onSubmit={ProcessService.updateProcess} 
                                category={object.categoryType}
                                onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                        />,
                        object.repeating ? 
                        <DeleteProcessModal 
                            bodyText={object.name} 
                            onDeleteOne={() => ProcessService.deleteProcessRepeating(1, object.id, object.startDate)}
                            onDeleteAll={() => ProcessService.deleteProcessRepeating(3, object.id, object.startDate)}
                            onDeleteThisAndNext={() => ProcessService.deleteProcessRepeating(2, object.id, object.startDate)}/> 
                        :
                        <DeleteModal itemName={object.name} onConfirm={() => ProcessService.deleteProcess(object)}/>
                    ]        
                }    
            }
        }
    />
}