import EstateService from "../services/EstateService"
import OverviewPage from './OverviewPage';
import PremisesPage from './PremisesPage';
import PremisePage from './PremisePage';
import BuildingsPage from './BuildingsPage';
import BuildingPage from './BuildingPage';
import FloorsPage from './FloorsPage';
import FloorPage from './FloorPage';
import RoomsPage from './RoomsPage';
import RoomPage from './RoomPage';
import SensorsPage from './SensorsPage';
import SensorPage from './SensorPage';
import BuildingPartsPage from "./BuildingPartsPage";

const EstateIndex = [
    {
      path: "overview",
      loader: () => EstateService.getHierarchyData("Overview"),
      element: <OverviewPage/>
    },
    {
      path: "premises",
      element: <PremisesPage/>
    },
    {
      path: "premises/:id",
      element: <PremisePage/>,
    },    
    {
      path: "buildings",
      element: <BuildingsPage/>
    },
    {
      path: "buildings/:id",
      element: <BuildingPage/>,
    },  
    {
      path: "floors",
      element: <FloorsPage/>
    }, 
    {
      path: "floors/:id",
      element: <FloorPage/>,
    },
    {
      path: "rooms",
      element: <RoomsPage/>
    },
    {
      path: "rooms/:id",
      element: <RoomPage/>,
    },
    {
      path: "sensors",
      loader: () => EstateService.getHierarchyData("Sensors"),
      element: <SensorsPage/>
    },
    {
      path: "sensors/:id",
      loader: ({params} : any) => EstateService.getHierarchyData("Sensors/" + params.id),
      element: <SensorPage/>,
    },    
    {
      path: "buildingParts",
      element: <BuildingPartsPage/>
    },
  ]

export default EstateIndex