import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Typography } from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';
import { RefreshDataContext } from '../../../common/contexts/RefreshDataContext';

export interface IDeleteProcessModalProps{  
  bodyText: string;
  buttonText?: string;
  size?: "small" | "medium" | "large";
  onDeleteOne: (() => Promise<void>);
  onDeleteAll: (() => Promise<void>);
  onDeleteThisAndNext: (() => Promise<void>);
}

export default function DeleteProcessModal(props: IDeleteProcessModalProps) {
  const [show, setShow] = useState(false);
  const [error, setError] = React.useState("");
  const [submitting, setSubmit] = React.useState(false);
  const handleRefreshData = useContext(RefreshDataContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function handleOne(){
    props.onDeleteOne()
    .then(() =>{
      setSubmit(false);
      handleClose();
      handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
    })    
  }
  function handleAll(){
    props.onDeleteAll()
    .then(() =>{
      setSubmit(false);
      handleClose();
      handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
    })    
  }
  function handleThisAndNext(){
    props.onDeleteThisAndNext()
    .then(() =>{
      setSubmit(false);
      handleClose();
      handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
    })    
  }
  return (
    <React.Fragment>
      <Button color="error" variant="contained" onClick={handleShow} size={props.size ? props.size : "medium"}>
        {props.buttonText ? props.buttonText : <AiFillDelete/>}
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Potvrzení</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Opravdu chtete smazat: {props.bodyText}
          {error !== "" && 
            <Typography component="i" color="red">
            Při zpracovávání vašeho požadavku došlo k chybě: {error}
            </Typography>                  
          }  
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button color="error" onClick={handleOne}>
            Tento
          </Button>
          <Button color="error" onClick={handleThisAndNext}>
            Tento a následující
          </Button>
          <Button color="error" onClick={handleAll}>
            Všechny
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
