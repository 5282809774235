import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";

export interface ISummaryProps{
    data: any[];
}

export default function FloorsSummary(props: ISummaryProps){
    const data = {
        count: props.data.length,
        areaSum: props.data.reduce((a, b) => a + b.area, 0).toFixed(2),
        roomAreaSum: props.data.reduce((a, b) => a + b.roomsArea, 0).toFixed(2)
    }
    return <PropertiesInfoTable
        data={data}
        rowDefinition={[{
            name : "Počet pater", 
            property : "count",            
        },{
            name : "Součet zadaných ploch", 
            property : "areaSum",            
        },{
            name : "Součet ploch prostorů", 
            property : "roomAreaSum",            
        },]}           
    />
}