import Sidebar, { LargeIconSize } from "../../common/components/Sidebar"
import STitle from "../../common/components/text/STitle"
import { useLocation } from "react-router-dom"
import { SkobaPremiseIcon, SkobaBuildingIcon, SkobaFloorIcon, SkobaRoomIcon, SkobaSensorIcon, SkobaItemsIcon, SkobaProcessesIcon, SkobaFilesIcon } from "../../common/components/icons/SkobaIcons"
import { SkobaColors } from "../../common/types/SkobaTheme"

const links = [{
        text: "Přehled",
        to: "/estate/overview",
        icon : <SkobaPremiseIcon sx={{fontSize:LargeIconSize}}/>                
    },
    {
        text: "Areály",
        to: "/estate/premises",
        icon : <SkobaPremiseIcon sx={{fontSize:LargeIconSize}}/>                
    },
    {
        text: "Budovy",
        to: "/estate/buildings",
        icon : <SkobaBuildingIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Patra",
        to: "/estate/floors",
        icon : <SkobaFloorIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Prostory",
        to: "/estate/rooms",
        icon : <SkobaRoomIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Části budov",
        to: "/estate/buildingParts",
        icon : <SkobaRoomIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Čidla",
        to: "/estate/sensors",
        icon : <SkobaSensorIcon sx={{fontSize:LargeIconSize}}/>
    }
]

export default function EstateSidebar(){
    const location = useLocation();
    return <Sidebar
    header={<STitle text="" />}
    items={links}
    getActive={(item) => location.pathname.includes(item.to)}
    color={SkobaColors.Gray2}
    toggle
/>
}