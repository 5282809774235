import { useNavigate } from "react-router-dom";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";

const PremisesHeader = ["Název", "Kód", "Adresa", "Plocha [m2]", "Budovy", "Prostory", "Plocha prostorů [m2]", "Prvky"];

export default function PremisesTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={PremisesHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name",
                    onClick: (object) => navigate("/estate/premises/" + object.id)
                },{
                    property: "code"
                },{
                    property: "adress"
                },{
                    property: "area"
                },{
                    property: "buildingsCount",
                    // onClick: (object) => navigate("/estate/premises/" + object.id) //navigate to buildings tab
                },{
                    property: "roomsCount"
                },{
                    property: "roomsArea"
                },{
                    property: "itemsCount"
                }
                ],
                // buttons: (object) => [
                //     <FormModal                         
                //         title="Upravit areál"    
                //         buttonElement={<AiFillEdit/>}
                //         form={<PremiseForm buttonText="Uložit změny" model={object} onSubmit={HierarchyService.updatePremise}/>}
                //     />,
                //     <DeleteModal 
                //         size="small" 
                //         itemName={object.name} 
                //         onConfirm={() => HierarchyService.deletePremise(object)}/>
                // ]                
            }
        }
    />
}