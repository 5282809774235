import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";

export interface ISummaryProps{
    data: any[];
}

export default function ProcessesSummary(props: ISummaryProps){
    const data = {
        premiseCount: props.data.length,
        costSum: props.data.reduce((a, b) => a + b.cost, 0),
        roomAreaSum: props.data.reduce((a, b) => a + b.roomsArea, 0)
    }
    return <PropertiesInfoTable
        data={data}
        rowDefinition={[{
            name : "Počet činností", 
            property : "premiseCount",            
        },{
            name : "Součet cen za procesy", 
            property : "costSum",            
        }]}           
    />
}