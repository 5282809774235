import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { formatFileSize } from '../../../common/Functions';
import "./FileUpload.css"

interface FileUploaderProps {
  onFileUpload: (file: File) => void;
  formError?: string;
  filename?: string;
  filesize?: number;
}

const FileUpload: React.FC<FileUploaderProps> = ({ onFileUpload, filename, filesize, formError }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState("");

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {          
            const selectedFile = acceptedFiles[0];
            if(selectedFile.size >= 1610612736)
              setError("Vybraný soubor nemůže být větší než 1.5 GB");
            else{
              setSelectedFile(selectedFile);
              onFileUpload(selectedFile);
              setError("");
              console.log(error)
            }
        }
        },
        [onFileUpload]
    );
    useEffect(() => {
      if(formError != undefined){
        setError(formError);
      }
    }, [formError])
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <div {...getRootProps()} style={error != "" ? dropzoneStyleError : dropzoneStyle}>
        <input {...getInputProps()} />
        {(filename && filesize && !selectedFile) &&(
			<div>
			<p>Název: {filename}</p>
			<p>Velikost: {formatFileSize(filesize * 1024)}</p>
        </div>
        )}
        {(!selectedFile && !filename) && (<div>Přetáhněte soubor, nebo kliknetě a soubor vyberte</div>)}
		{selectedFile && (
			<div>
			<p>Název: {selectedFile.name}</p>
			<p>Velikost: {formatFileSize(selectedFile.size)}</p>
		</div>
        )}
		{(!selectedFile && error) && (<div style={{color:"#CC2626"}}>{error}</div>)}
      </div>
    );
};

const dropzoneStyle: React.CSSProperties = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  textAlign: 'center',
  padding: '30px',
  cursor: 'pointer',
};
const dropzoneStyleError: React.CSSProperties = {
  border: '2px dashed #CC2626',
  borderRadius: '4px',
  textAlign: 'center',
  padding: '30px',
  cursor: 'pointer',
};

export default FileUpload;