import ControlMap from "../../estate/components/maps/ControlMap";
import LocalMap from "../../estate/components/maps/LocalMap";
import Matterport from "../../estate/components/maps/Matterport";
import ChartManager from "../charts/ChartManager";

export enum SkobaEntityType{
    Premise = 0,
    Building = 1,
    Floor = 2,
    Room = 3,
    Sensor = 4,
    Item = 5,
    Process = 6,
    File = 7,
    Geometry = 8,
    Line = 9,
    Point = 10,
    Polygon = 11
}

export enum CategoryType{    
    Activity = 0,
    Item = 1,
    ItemSpecific = 2,
    File = 3,
    BuildingPart = 4,
    Revision = 5,
}
export interface ISkobaEntity{    
    id: any;
}
export interface ISkobaEntityNamed extends ISkobaEntity {
    name: string;
}
export interface ISkobaEntityNamedCreate {
    name: string;
}

export interface ISelect{
    id: number;
    name: string;
}
export interface ISelectHierarchical{
    id: number;
    parentId: number;
    name: string;
}
export interface ISelectBetter{
    id: number;
    name: string;
    type: SkobaEntityType;
}

export interface IEstateEntityWithSelect<T>{
    select: ISelect[];
    values: T[];
}

export interface ISelect {
    id: number;
    name: string;
}
export interface IConnectorEntity extends ISkobaEntity{
    parentId : number;
    parentType: SkobaEntityType;
    childId: number;
    childType: SkobaEntityType;
}
export interface IConnectorEntityDetailed extends IConnectorEntity{
    parentName: string;
}

export interface IWFSResponse{
    connectors: IConnectorEntityDetailed[];
    geoJSON: string;
}

export interface IMatterportDTO{
    id: number;
    name: string;
    url: string;
    password: string;
}

export const selectTypesItem = [
    {
        name: "Areál",
        type: 0
    },
    {
        name: "Budova",
        type: 1
    },
    {
        name: "Podlaží",
        type: 2
    },
    {
        name: "Prostor",
        type: 3
    }
]
export const selectTypesProcess = [
    {
        name: "Areál",
        type: 0
    },
    {
        name: "Budova",
        type: 1
    },
    {
        name: "Podlaží",
        type: 2
    },
    {
        name: "Prostor",
        type: 3
    },
    {
        name: "Prvek",
        type: 5
    }
]
export const selectTypesFile = [
    {
        name: "Areál",
        type: 0
    },
    {
        name: "Budova",
        type: 1
    },
    {
        name: "Podlaží",
        type: 2
    },
    {
        name: "Prostor",
        type: 3
    },
    {
        name: "Prvek",
        type: 5
    },
    {
        name: "Proces",
        type: 6
    }
]
export interface ISideWindowControl{
    changeSideWindow: (type: number) => void;
    openSideWindow: () => void;
    closeSideWindow: () => void;    
    closeHierarchy: () => void;    
    openHierarchy: () => void;    
    setSwitcherOptions: (has2D : boolean, has3D: boolean) => void;    
    chartObj?: ChartManager;
    matterport?: Matterport;
    controlMap?: ControlMap;    
    localMap?: LocalMap;
}
export interface IBaseHierarchyProps{
    onClose: () => void;
    onHierarchyChange: (level: number, id: number | null) => void;
    sideWindowControl: ISideWindowControl;
}

