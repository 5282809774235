import { SkobaEntityType } from "./types/skobaTypes";
import { IFileDownload } from "../files/types/fileTypes";
import dayjs from "dayjs";

export function appendParentToData(data: any): any | null{
    if(data.locationType === SkobaEntityType.Premise){
        data["premiseId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.Building){
        data["buildingId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.Floor){
        data["floorId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.Room){
        data["roomId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.Sensor){
        data["sensorId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.Item){
        data["itemId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.Process){
        data["processId"] = data.location;
    }
    else if(data.locationType === SkobaEntityType.File){
        data["fileId"] = data.location;
    }
    else
        return null;
    return data;
}
export function getAppendedParentFormData(data: any): FormData | null{
    const formData = new FormData();
    if(data.locationType === SkobaEntityType.Premise){
        formData.append("premiseId", data.location);
    }
    else if(data.locationType === SkobaEntityType.Building){
        formData.append("buildingId", data.location);
    }
    else if(data.locationType === SkobaEntityType.Floor){
        formData.append("floorId", data.location);
    }
    else if(data.locationType === SkobaEntityType.Room){
        formData.append("roomId", data.location);
    }
    else if(data.locationType === SkobaEntityType.Sensor){
        formData.append("sensorId", data.location);
    }
    else if(data.locationType === SkobaEntityType.Item){
        formData.append("itemId", data.location);
    }
    else if(data.locationType === SkobaEntityType.Process){
        formData.append("processId", data.location);
    }
    else if(data.locationType === SkobaEntityType.File){
        formData.append("fileId", data.location);
    }
    else
        return null;
    return formData;
}
export function getParentType(item : any) : number{
    if(item.premiseId !== null)
        return SkobaEntityType.Premise;    
    else if(item.buildingId !== null)
        return SkobaEntityType.Building;
    else if(item.floorId !== null)
        return SkobaEntityType.Floor;
    else if(item.roomId !== null)
        return SkobaEntityType.Room;
    else if(item.itemId !== null)
        return SkobaEntityType.Item;
    else if(item.processId !== null)
        return SkobaEntityType.Process;
    else
        return -1;
}
export function getParentName(item : any) : number{
    if(item.premiseId !== null)
        return item.premiseName;    
    else if(item.buildingId !== null)
        return item.buildingName;
    else if(item.floorId !== null)
        return item.floorName;
    else if(item.roomId !== null)
        return item.roomName;
    else if(item.itemId !== null)
        return item.itemName;
    else if(item.processId !== null)
        return item.processName;
    else
        return -1;
}
export function getParentRoute(item : any) : string{
    if(item.premiseId !== null)
        return "/estate/premises/" + item.premiseId;    
    else if(item.buildingId !== null)
        return "/estate/buildings/" + item.buildingId;
    else if(item.floorId !== null)
        return "/estate/floors/" + item.floorId;
    else if(item.roomId !== null)
        return "/estate/rooms/" + item.roomId;
    else if(item.itemId !== null)
        return "/items/" + item.itemId;
    else if(item.processId !== null)
        return "/processes/" + item.processId;
    else
        return "";
}
export function getConnectorParentRoute(item : any) : string{
    switch(item.locationType){
        case SkobaEntityType.Premise:
            return "/estate/premises/" + item.locationId;
        case SkobaEntityType.Building:
            return "/estate/buildings/" + item.locationId;
        case SkobaEntityType.Floor:
            return "/estate/floors/" + item.locationId;
        case SkobaEntityType.Room:
            return "/estate/rooms/" + item.locationId;
        case SkobaEntityType.Item:
            return "/items/" + item.locationId;
        case SkobaEntityType.Sensor:
            return "/estate/sensors/" + item.locationId;
        case SkobaEntityType.Process:
            return "/processes/" + item.locationId;
        case SkobaEntityType.File:
            return "/files/" + item.locationId;
        default:
            return "";
    }
}
export function getParentLocationId(item : any) : number{
    if(item.premiseId !== null)
        return item.premiseId;
    else if(item.buildingId !== null)
        return item.buildingId;
    else if(item.floorId !== null)
        return item.floorId;
    else if(item.roomId !== null)
        return item.roomId;    
    else if(item.itemId !== null)
        return item.itemId;
    else if(item.processId !== null)
        return item.processId;
    else
        return -1;    
}

// export function getItemsTable(data : IItemBase[], categoryFilter : number, subCategoryFilter : number, onHierarchyChange: (level: number, id: number | null) => void) : ReactElement{
//     return <GTableHierarchy 
//                 data={data}
//                 specificLinks={[]}
//                 // onClickLink={onHierarchyChange}
//                 header={getItemTableHeader(categoryFilter, subCategoryFilter).concat([["$parent", "Lokace"]])}            
//                 clickableLinks={[["name", "id", 6]]}
//                 hoverableLinks={[]}          
//                 idProps={["id", "name"]}
//                 parentColumns={[["premiseName","premise", 1], ["buildingName","building", 2], ["floorName","floor", 3], ["roomName","room", 4]]}
//         />
// }

export function downloadFile(file: IFileDownload) : void{
    const blob = new Blob([file.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.filename;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

export function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
  
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const formattedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
  
    return `${formattedSize} ${sizes[i]}`;
}
export function formatDatetime(datetime : any): string {
    const date = new Date(datetime);
    return dayjs(datetime).format('DD.MM.YYYY HH:mm');
}

export function getMainColor(): string{
    return "#3b85b8"
}