import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

export interface IChartProps{
    data: any;
    title: string;
	left: boolean;
}
const renderActiveShape = (props : any) => {
	const RADIAN = Math.PI / 180;
	const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, lang } = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={-1} textAnchor="middle" fill={fill}>
				{payload.name}
			</text>
			<text x={cx} y="10%" dy={-1} textAnchor="middle" fill="black">
				{lang}
			</text>
			<text x={cx} y={cy} dy={22} textAnchor="middle" fill={fill}>
				Počet: {value}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
		</g>
	);
};

const COLORS = ['#586994', '#7D869C', '#A2ABAB', '#B4C4AE', '#E5E8B6'];

export default function DonutChart(props : IChartProps){
	const [activeIndex, setActiveIndex] = useState(1);	
	function onPieEnter(_ : any, index : number){
		setActiveIndex(index)
	}
	return (
	<div style={{ width: '100%', height: '100%' }}>
		<Typography sx={{
			position: "absolute",            
			top: "50%",
			right: props.left ? "75%" : "25%",
			transform: "translate(50%, 0)",
            fontWeight: "bold"
			}}>
			{props.title}
		</Typography>
		<ResponsiveContainer>
			<PieChart>
				<Pie
				activeIndex={activeIndex}
				activeShape={renderActiveShape}
				data={props.data}
				cx="50%"
				cy="50%"
				innerRadius={60}
				outerRadius={80}
				fill="#8884d8"
				dataKey="value"
				onMouseEnter={onPieEnter}				
				>
					{props.data.map((entry : any, index : number) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	</div>
		
	)
}