import { useLocation } from "react-router-dom";
import { SkobaOverviewIcon, SkobaUserIcon, SkobaPaymentIcon } from "../../common/components/icons/SkobaIcons";
import Sidebar, { LargeIconSize } from "../../common/components/Sidebar";
import STitle from "../../common/components/text/STitle";

const links = [{
    text: "Přehled",
    to: "/admin",
    icon : <SkobaOverviewIcon sx={{fontSize:LargeIconSize}}/>
    },{
    text: "Uživatelé",
    to: "/admin/users",
    icon : <SkobaUserIcon sx={{fontSize:LargeIconSize}}/>
    },{
    text: "Platby",
    to: "/admin/payments",
    icon : <SkobaPaymentIcon sx={{fontSize:LargeIconSize}}/>
}]

export default function AdminSidebar(){    
    const location = useLocation();
    return <Sidebar
        header={<STitle text="" />}
        items={links}
        getActive={(item) => location.pathname == item.to}
        color="#d9d9d9"
        toggle
    />
}