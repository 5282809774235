import HierarchyMultipleFilterPageNew from "../../common/pages/HierarchyMultipleFilterPageNew";
import ItemsHierarchy from "../components/content/ItemsHierarchy";
import ItemService from "../services/ItemService";
import EstateService from "../../estate/services/EstateService";
import { data } from "jquery";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";

export default function ItemsPage(){    
    return <HierarchyMultipleFilterPageNew title="Prvky">
        <ItemsHierarchy 
        getData={async (category: CategoryType, subCategory: CategoryType) => ItemService.getItems(category, subCategory)}       
        />
    </HierarchyMultipleFilterPageNew>
  }