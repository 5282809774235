import { IOrganization } from "../../types/adminTypes"
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable"

export interface IOrganizationInfoProps{
    data : IOrganization
}

export default function OrganizationInfo(props : IOrganizationInfoProps){    
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={[{
            name : "Název zákazníka", 
            property : "name",            
        },{
            name : "Adresa", 
            property : "adress",             
        },{
            name : "Město", 
            property : "city",                  
        },{
            name : "PSČ", 
            property : "postalCode",            
        },{
            name : "IČO", 
            property : "cin",            
        },{
            name : "Číslo zákazníka", 
            property : "id",            
        }]}
    />
}