import React from 'react';
import { FormikProvider, useFormik } from 'formik';
 import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import getStripe from '../../../payment/services/getStripe';

 const SubscriptionValidationScheme = Yup.object().shape({
    plan: Yup.number().required().oneOf([0,1], ''),
    frequency: Yup.number().required().oneOf([0,1], ''),
    paymentType: Yup.number().required().oneOf([0,1], '')
});

function getLineItems(frequency : number){
    switch(frequency){
        case 0: 
            return [
                {
                price: "price_1Mj2s1CMWV2jXqTnJMOMWvZE",
                quantity: 1,
                },
            ];
        case 1: 
            return [
                {
                price: "price_1MsnwsAfwLwwkMWhhiFDHuby",
                quantity: 1,
                },
            ];
        default: 
            return [];
    }
}

export default function SubscriptionForm() {
    const formik = useFormik({
        initialValues: {
            plan: 0,
            frequency: 0,
            paymentType: 0           
        },
        validationSchema: SubscriptionValidationScheme,
        onSubmit: (out) => {handleCheckout(out)},
    });
    async function handleCheckout(out: any) {
        const succUrl = process.env.REACT_APP_URL + `/payment/success?&plan=` + out.plan + `&frequency=` + out.frequency + `&timestamp=` + Date.now()
        const stripe = await getStripe();
        if(out.paymentType === 0){
            const { error } = await stripe.redirectToCheckout({
                lineItems: getLineItems(out.frequency),
                mode: 'subscription',
                successUrl: succUrl,
                cancelUrl: process.env.REACT_APP_URL + `/payment/cancel`,
                customerEmail: 'customer@email.com',
              });
              console.warn(error.message);
        }
        else{
            
        }
        
      }
    function getPlanSection() : JSX.Element{
        return <React.Fragment>
            <Typography fontWeight="bold">
                Plán:
            </Typography>
            <Grid container spacing={1} sx={{pb:2}}>
                <Grid item xs={6}>     
                    <Button fullWidth variant={formik.values.plan === 0 ? "contained" : "outlined"} 
                        onClick={() => formik.setFieldValue("plan", 0)}>
                        Plán 1
                    </Button>           
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant={formik.values.plan === 1 ? "contained" : "outlined"} 
                        onClick={() => formik.setFieldValue("plan", 1)}>
                        Plán 2 
                    </Button>          
                </Grid>
            </Grid>
        </React.Fragment>
    }
    function getFrequencySection() : JSX.Element{
        return <React.Fragment>
            <Typography fontWeight="bold">
                Frequence plateb:
            </Typography>
            <Grid container spacing={1} sx={{pb:2}}>
                <Grid item xs={6}>     
                    <Button fullWidth variant={formik.values.frequency === 0 ? "contained" : "outlined"} 
                        onClick={() => formik.setFieldValue("frequency", 0)}>
                        Mešíčně
                    </Button>           
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant={formik.values.frequency === 1 ? "contained" : "outlined"} 
                        onClick={() => formik.setFieldValue("frequency", 1)}>
                        Ročně
                    </Button>          
                </Grid>
            </Grid>
        </React.Fragment>
    }
    function getPaymentTypeSection() : JSX.Element{
        return <React.Fragment>
            <Typography fontWeight="bold">
                Způsob platby:
            </Typography>
            <Grid container spacing={1} sx={{pb:2}}>
                <Grid item xs={6}>     
                    <Button fullWidth variant={formik.values.paymentType === 0 ? "contained" : "outlined"} 
                        onClick={() => formik.setFieldValue("paymentType", 0)}>
                        Kartou
                    </Button>           
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant={formik.values.paymentType === 1 ? "contained" : "outlined"} 
                        onClick={() => formik.setFieldValue("paymentType", 1)}>
                        Převodem
                    </Button>          
                </Grid>
            </Grid>
        </React.Fragment>
    }
    return <FormikProvider value={formik}>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { mb: 1.5},
            }}
            onSubmit={formik.handleSubmit}
        >   
            {getPlanSection()}
            {getFrequencySection()}
            {getPaymentTypeSection()}            
            <Typography fontWeight="bold" textAlign="center">
                Částka: XXX Kč {formik.values.frequency === 0 ? "měsíčně" : "ročně"}
            </Typography>
            <Button color="primary" variant="contained" fullWidth type="submit">
                Zaplatit
            </Button>
        </Box>
    </FormikProvider >    
}