import { formatFileSize } from "../../../common/Functions";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";

export interface ISummaryProps{
    data: any[];
}

export default function FilesSummary(props: ISummaryProps){
    const data = {
        count: props.data.length,
        sumSize: props.data.reduce((a, b) => a + b.size, 0),
    }
    return <PropertiesInfoTable
        data={data}
        rowDefinition={[{
            name : "Počet souborů", 
            property : "count",            
        },{
            name : "Součet velikostí", 
            property : "sumSize",            
            getValue: (sumSize) => formatFileSize(Number(data.sumSize) * 1024)
        }]}           
    />
}