import ApiService from "../../common/services/ApiService";
import { ISubscriptionCreate } from "../types/adminTypes";
  
const createSubscription = (subscription : ISubscriptionCreate) => {
    return ApiService.apiPost("/subscriptions", subscription);
};

const createAndPaySubscription = (subscription : ISubscriptionCreate) => {
    return ApiService.apiPost("/subscriptions/pay", subscription);
};

const SubscriptionService = {
    createSubscription,
    createAndPaySubscription
};

export default SubscriptionService;