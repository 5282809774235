import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import PaymentsTable from "../components/tables/PaymentsTable";
import { GError, Loading } from "../../common/layouts/Components";
import TabLayout from "../../common/layouts/TabLayout";
import STitle from "../../common/components/text/STitle";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import AdminService from "../services/AdminService";
import { ISubscription } from "../types/adminTypes";

export default function PaymentsPage(){
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<ISubscription[]>([]);
    // const [openPayments, setOpenPayments] = useState(false);
    
    useEffect(() => getData(), [])
    function getData(){
        AdminService.getSubscriptions()
        .then(
            (result) => {
                setLoaded(true);
                setData(result as ISubscription[]);
            },
            (error) => {
                setLoaded(true);
            setError(error);
            }
        )
    }
    if (error)
        return <GError error={error.message}/>
    else if (!loaded)
        return <Loading/>;
    else {
        return (<React.Fragment>
            <RefreshDataContext.Provider value={getData}>
                <Box sx={{p:2}}>                
                    <STitle text="Platby"/>
                    <TabLayout queryPrefix="pp" titles={["Platby"]}>
                        <PaymentsTable data={data}/>          
                    </TabLayout>
                </Box>                
            </RefreshDataContext.Provider>            
        </React.Fragment>
    )}
}