import { getGeometrytyle, getLineStyle, getPointStyle, getPolygonStyle } from "./LocalMapStyles";
import { IWFSLayer } from "./LocalMap";
import { RLayerBaseVector,  RLayerWMS,  VectorSourceEvent } from "rlayers";
import { GeoJSON } from "ol/format";
import Feature from "ol/Feature";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import { PieChart } from "react-minimal-pie-chart";
import { SkobaEntityType } from "../../../common/types/skobaTypes";
import { Extent } from "ol/extent";
import { ProjectionLike } from "ol/proj";
import { FeatureLoader } from "ol/featureloader";
import { RLayerBaseVectorProps } from "rlayers/layer/RLayerBaseVector";
import Geometry from "ol/geom/Geometry";
import GeodataService from "../../services/GeodataService";
import React from "react";

export enum GeometryType{
    Point = 0,
    Line = 1,
    Polygon = 2,
    Geometry = 3
}

export const createCustomLoader = (url: string, onLoadEnd: () => void): FeatureLoader => {
    return (
        extent: Extent,
        resolution: number,
        projection: ProjectionLike,
        success: ((features: Feature<Geometry>[]) => void) | undefined,
        failure: (() => void) | undefined,
    ): void => {
        GeodataService.getWFS(url)
        .then(response => {
            const features = new GeoJSON().readFeatures(response.geoJSON, {
                featureProjection: projection
            }) as Feature<Geometry>[];
            features.forEach(x => {
                const connector = response.connectors.find(y => y.childId == parseInt((x.getId()?.toString() as string).split(".")[1]))
                x.set("connector", connector)
            })
            if (success) {
                success(features);
                onLoadEnd();
            }
        })
        .catch(error => {
            console.error('Error loading WFS data:', error);
            if (failure) {
                failure();
            }
        });
    };
};

export function prepareWFSLink(id: number, type: SkobaEntityType): IWFSLayer[]{
    const wfsLayers = [] as IWFSLayer[];
    wfsLayers.push({
        link : getWFSLink(id, type, GeometryType.Polygon),
        style : getPolygonStyle,
        type: GeometryType.Polygon
    } as IWFSLayer);   
    wfsLayers.push({
        link : getWFSLink(id, type, GeometryType.Line),
        style : getLineStyle,
        type: GeometryType.Line
    } as IWFSLayer);   
    wfsLayers.push({
        link : getWFSLink(id, type, GeometryType.Geometry),
        style : getGeometrytyle,
        type: GeometryType.Geometry
    } as IWFSLayer);
    wfsLayers.push({
        link : getWFSLink(id, type, GeometryType.Point),
        style : getPointStyle,
        type: GeometryType.Point
    } as IWFSLayer);
    return wfsLayers;      
}

export function getWFSLink(id: number, type: SkobaEntityType, linkType: GeometryType): string{
    switch (linkType){
        case GeometryType.Point:
            return "point/parentId=" + id + "&parentType=" + type;
        case GeometryType.Line:
            return "line/parentId=" + id + "&parentType=" + type;
        case GeometryType.Polygon:
            return "polygon/parentId=" + id + "&parentType=" + type;
        case GeometryType.Geometry:
            return "geometry/parentId=" + id + "&parentType=" + type;
        default: return ""
    }
}

export function onFeaturesLoadEnd(this: RLayerBaseVector<Feature<Geometry>, RLayerBaseVectorProps<Feature<Geometry>>>, e: VectorSourceEvent<Feature<Geometry>>): boolean | void{
    if(e.features !== undefined){
        this.source.addFeatures(e.features);
    }
    
}

export function generatePieChart(features: Feature<Geometry>[]): JSX.Element{
    const buildings = features.filter(x => x.get("type") === "Building")
    const premises = features.filter(x => x.get("type") === "Premise")
    const pieData: Data<any> = []; 
    if(buildings.length !== 0)
    pieData.push({value: buildings.length, color: 'blue', title : buildings.length})
    if(premises.length !== 0)
    pieData.push({value: premises.length, color: 'orange', title : premises.length })
    return <PieChart className={"pieCCCCC"} data={pieData} viewBoxSize={[80,80]} center={[50,50]} radius={20} label={({ dataEntry }) => dataEntry.title}/>
}
export function getWMSLayers(option: number){
    switch(option){
        case 1:
            return <RLayerWMS
            url="https://services.cuzk.cz/wms/wms.asp?"
            params={{
                LAYERS: 'KN', // Specify the WMS layer
                FORMAT: 'image/png',
                TRANSPARENT: true
            }}
            />
        case 2: 
        return <RLayerWMS
            url="https://services.cuzk.cz/wms/wms.asp?"
            params={{
                LAYERS: 'prehledky', // Specify the WMS layer
                FORMAT: 'image/png',
                TRANSPARENT: true
            }}
            />
        case 3: 
        return <React.Fragment>
                <RLayerWMS
                url="https://services.cuzk.cz/wms/wms.asp?"
                params={{
                    LAYERS: 'KN', // Specify the WMS layer
                    FORMAT: 'image/png',
                    TRANSPARENT: true
                }}
                />
                <RLayerWMS
                url="https://services.cuzk.cz/wms/wms.asp?"
                params={{
                    LAYERS: 'prehledky', // Specify the WMS layer
                    FORMAT: 'image/png',
                    TRANSPARENT: true
                }}
                />
            </React.Fragment>
        default: 
            return <div></div>
        
    }
    return <React.Fragment>
        <RLayerWMS
        url="https://services.cuzk.cz/wms/wms.asp?"
        params={{
            LAYERS: 'KN', // Specify the WMS layer
            FORMAT: 'image/png',
            TRANSPARENT: true
        }}
        />
        <RLayerWMS
        url="https://services.cuzk.cz/wms/wms.asp?"
        params={{
            LAYERS: 'prehledky', // Specify the WMS layer
            FORMAT: 'image/png',
            TRANSPARENT: true
        }}
        />
    </React.Fragment>
}