import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideWindowContext } from "../../contexts/SideWindowContext";
import SingleTextFilter from "../../../common/components/filters/SingleTextFilter";
import SensorsTable from "../tables/SensorsTable";
import { ISelect } from "../../../common/types/skobaTypes";
import { ISensorDetailed } from "../../types/estateTypes";
import HierarchyFiltersMultipleNew from "../../../common/components/hierarchy/HierarchyFiltersMultipleNew";
import LoadingWrapper from "../../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../../common/contexts/RefreshDataContext";
import PrintableWrapper from "../../../common/components/PrintableWrapper";


export interface ISensorsHierarchyProps{
    parent: ISelect;    
    getData : () => Promise<any[]>;
}

export default function SensorsHierarchy(props: ISensorsHierarchyProps){    
    const { changeSideWindow, openSideWindow, closeSideWindow, openHierarchy, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<ISensorDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        openHierarchy();
        closeSideWindow();
    }, [])

    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }

    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>            
        <PrintableWrapper>
            <HierarchyFiltersMultipleNew
                data={data}
                getChildrenTable={(data) => <SensorsTable data={data}/>}
                getFilters={(data, onChange) => 
                    <SingleTextFilter 
                    data={data} 
                    onChange={onChange} 
                    filterProperty="name"
                    labelText="Hledat dle názvu čidla"/> 
                }
                modifyButtons={[]}
            />
        </PrintableWrapper>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }