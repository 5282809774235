import ApiService from "../../common/services/ApiService";
import { IMatterportDTO, IWFSResponse, SkobaEntityType } from "../../common/types/skobaTypes";

const getWFS = (url: string) : Promise<IWFSResponse> => {
    return ApiService.apiGet("/WFS/" + url);
};
const getMatterport = (id: number, type: SkobaEntityType) : Promise<IMatterportDTO> => {
    return ApiService.apiGet("/Matterport?id=" + id + "&type=" + type);
};
const GeodataService = {
    getWFS,
    getMatterport
};

export default GeodataService;