import { Box, Button, ButtonGroup, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SideWindowType } from '../../layouts/OverviewSplitManager';

export interface IViewSwitcherProps{
    has3D: boolean;
    has2D: boolean;
    currentView: SideWindowType;
    onClick: (type: number) => void;
}

export default function ViewSwitcher(props : IViewSwitcherProps){
    const [activeView, setActiveView] = useState(SideWindowType.LocalMap);
    useEffect(() => {
        if((props.has3D && props.has2D ) || props.has2D)
            setActiveView(SideWindowType.LocalMap)
        else if(props.has3D)
            setActiveView(SideWindowType.Matterport)

    }, [])
    useEffect(() => {
        setActiveView(props.currentView)
    }, [props.currentView])
    function onClick(view: number){
        if(view !== activeView){
            setActiveView(view);
            props.onClick(view);
        }
    }
    return <Box sx={{
        p: 0,
        position: "absolute",
        top: "0",
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex: 100,
        display: "flex",
        flexDirection: "column"
        }}>                        
            <Paper elevation={3} sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingX: 1,
                    display: 'flex',
                    flexDirection: 'row',  
                    gap: "5px",
                    backgroundColor: "#f2f2f2"
                }}>
                <ButtonGroup size="small" variant="contained">
                <Button 
                    disabled={!props.has2D}
                    onClick={() => onClick(SideWindowType.LocalMap)} 
                    variant={activeView === SideWindowType.LocalMap ? "contained" : "outlined"}>
                        Mapa budovy
                    </Button>
                <Button 
                    disabled={!props.has3D} 
                    onClick={() => onClick(SideWindowType.Matterport)} 
                    variant={activeView === SideWindowType.Matterport ? "contained" : "outlined"}>
                        3D prohlídka
                    </Button>
            </ButtonGroup>
            </Paper>                   
    </Box>
    
}
