import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import SidebarLayout from './modules/common/layouts/SidebarLayout';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, CssBaseline } from '@mui/material';
import SignInPage from './modules/auth/pages/SignIn';
import { AdminLayout, ProtectedLayout, PublicLayout } from './modules/common/layouts/Layouts';
import SignUpPage from './modules/auth/pages/SignUp';
import ForgottenPasswordPage from './modules/auth/pages/ForgottenPassword';
import ChangePasswordPage from './modules/auth/pages/ChangePassword';
import ResetPasswordPage from './modules/auth/pages/ResetPassword';
import FailPage from './modules/payment/pages/Fail';
import SuccessPage from './modules/payment/pages/Success';
import AdamStarter from './modules/adam/pages/AdamStarter';
import SplitScreenSidebarLayout from './modules/estate/layouts/OverviewSidebarLayout';
import SkobaSidebar from './modules/common/components/SkobaSidebar';
import PaddedContentSidebarLayout from './modules/common/layouts/PaddedContentSidebarLayout';
import { csCZ as coreCsCZ } from '@mui/material/locale';
import { csCZ } from '@mui/x-date-pickers/locales';
import AdminIndex from './modules/admin/pages/AdminIndex';
import EstateIndex from './modules/estate/pages/EstateIndex';
import EstateSidebar from './modules/estate/components/EstateSidebar';
import FilesSidebar from './modules/files/components/FilesSidebar';
import ItemsIndex from './modules/items/pages/ItemsIndex';
import ProcessesIndex from './modules/processes/pages/ProcessesIndex';
import FilesIndex from './modules/files/pages/FilesIndex';
import ProcessesSidebar from './modules/processes/components/ProcessesSidebar';
import ItemsSidebar from './modules/items/components/ItemsSidebar';
import AdminSidebar from './modules/admin/components/AdminSidebar';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function getPage(content : React.ReactElement) : React.ReactElement{
  return <Container 
    maxWidth={false}
    disableGutters={true}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {content}
  </Container>
}

function getPublicPage(content : React.ReactElement) : React.ReactElement{
  return <PublicLayout>
    {/* <React.StrictMode> */}
      {content}
    {/* </React.StrictMode>    */}
  </PublicLayout>
}
const theme = createTheme({
  typography: {
    "fontFamily": `"Heebo"`,
  },
  palette: {
    background: {
      default: "#ffffff",      
    },
    primary:{
      main: "#3b85b8",      
    },
    secondary:{
      main: "#4a4a4a",
    },
    error:{
      main: "#CC2626"
    }
  }},
  csCZ,
  coreCsCZ
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedLayout>
      <SidebarLayout
        level={0}
        sideBarContent={<SkobaSidebar/>}
      />
    </ProtectedLayout>,
    children: [
      {
        path: "estate",
        element: <SplitScreenSidebarLayout sidebar={<EstateSidebar/>}/>,
        children: EstateIndex
      },
      {
        path: "items",
        element: <SplitScreenSidebarLayout sidebar={<ItemsSidebar/>}/>,
        children: ItemsIndex
      },
      {
        path: "processes",
        element: <SplitScreenSidebarLayout sidebar={<ProcessesSidebar/>}/>,
        children: ProcessesIndex
      },
      {
        path: "files",
        element: <SplitScreenSidebarLayout sidebar={<FilesSidebar/>}/>,
        children: FilesIndex
      },
      {
        path: "admin",
        element: <AdminLayout>
          <PaddedContentSidebarLayout
            level={1}
            contentBackground='#e6e6e6'
            sideBarContent={<AdminSidebar/>}
          />
        </AdminLayout>,
        children: AdminIndex
      },
      {        
        path: "adamStarter",
        element: <AdamStarter/>,
      }
    ]
  },
  
  {
    path: "/signin",
    element: getPublicPage(getPage(<SignInPage/>))
  },
  {
    path: "/signup",
    element: getPublicPage(getPage(<SignUpPage/>))
  },
  {
    path: "/forgottenPassword",
    element: getPublicPage(getPage(<ForgottenPasswordPage/>))
  },
  {
    path: "/resetPassword",    
    element: getPublicPage(getPage(<ResetPasswordPage/>))
  },
  {
    path: "/changePassword",
    element: getPublicPage(getPage(<ProtectedLayout><ChangePasswordPage/></ProtectedLayout>))
  },
  {        
    path: "/payment/success",
    element: getPublicPage(getPage(<SuccessPage/>)),
  },
  {        
    path: "/payment/fail",
    element: getPublicPage(getPage(<FailPage/>)),
  }
]);


root.render(
  <ThemeProvider theme={theme} >
    <CssBaseline />
    {/* <StrictMode> */}
      <RouterProvider router={router} />
    {/* </StrictMode> */}
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
