import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowType } from "../layouts/OverviewSplitManager";
import { SideWindowContext } from "../contexts/SideWindowContext";
import EstateService from "../services/EstateService";
import RoomsHierarchy from "../components/content/RoomsHierarchy";
import FilesHierarchy from "../components/content/FilesHierarchy";
import ProcessesHierarchy from "../components/content/ProcessesHierarchy";
import SensorsHierarchy from "../components/content/SensorsHierarchy";
import FloorInfo from "../components/tables/FloorInfo";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import { IFloorDetailed } from "../types/estateTypes";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import { prepareWFSLink } from "../components/maps/MapFunctions";
import ItemsHierarchy from "../../items/components/content/ItemsHierarchy";
import ItemService from "../../items/services/ItemService";
import FileService from "../../files/services/FileService";
import PrintableWrapper from "../../common/components/PrintableWrapper";


export default function FloorPage(){
    const { changeSideWindow, openSideWindow, openHierarchy, localMap, matterport, setSwitcherOptions} = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IFloorDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
    }, [])
    useEffect(() => {
        if(data !== undefined){
            setSwitcherOptions(data.hasDrawing, data.hasMatterport)
            if(data?.hasDrawing){
                openSideWindow();
                changeSideWindow(SideWindowType.LocalMap);
            }
            else if(data?.hasMatterport){
                openSideWindow();
                changeSideWindow(SideWindowType.Matterport);
            }
        }
        
    }, [data])
    useEffect(() => {
        if(data !== undefined){
            setMapVectorData();
        }
        
    }, [data, localMap])
    useEffect(() => {
        if(data !== undefined){
            setMatterportData();
        }
        
    }, [data, matterport])
    function loadData(){
        EstateService.getHierarchyData("Floors/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(data && localMap){          
            localMap?.setActiveConnectors([{id: data.id, type: SkobaEntityType.Floor}])
            localMap?.setWfsLayers(prepareWFSLink(data.id, SkobaEntityType.Floor));
        }       
    }
    function setMatterportData(): void{
        if(data && matterport){          
            matterport?.loadData(data.id, SkobaEntityType.Floor)
        }       
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew 
                title={data!.name} 
                id={data.id} 
                type={SkobaEntityType.Floor} 
                tabTitles={["Informace", "Prostory", "Čidla", "Prvky", "Revize", "Činnosti", "Soubory"]}>
                    <PrintableWrapper>                        
                        <FloorInfo data={data} onMount={() => {
                            if(data?.hasDrawing){
                                openSideWindow();
                                changeSideWindow(SideWindowType.LocalMap);
                                setMapVectorData();
                            }
                            else if(data?.hasMatterport){
                                openSideWindow();
                                changeSideWindow(SideWindowType.Matterport);
                            }
                        }}
                        onRefresh={() => {}}
                        refreshProps={[data, localMap]}/>
                    </PrintableWrapper>
                    <RoomsHierarchy
                        parent={{id: data.id, name: data.name}}
                        floorOptions={{id: data.id, name: data.name}}
                        showLocalMap={data.hasDrawing}
                        showMatterport={data.hasMatterport}
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Floor, data.id, SkobaEntityType.Room)}/>
                    <SensorsHierarchy 
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Floor, data.id, SkobaEntityType.Sensor)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Floor}}
                        getData={async (category: CategoryType, subCategory: CategoryType) => ItemService.getAllOfParent(SkobaEntityType.Floor, data.id, category, subCategory)}/>
                    <ProcessesHierarchy 
                        category={CategoryType.Revision}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Floor}} />
                    <ProcessesHierarchy 
                        category={CategoryType.Activity}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Floor}} />
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Floor, data.id)}
                        parent={{name: data.name, id: data.id, type: 2}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }