import { data } from "jquery";
import EstateService from "../../services/EstateService";
import PremiseForm from "../forms/PremiseForm";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import FormModal from "../../../categories/components/modals/FormModal";
import { useEffect } from "react";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";
import { useNavigate } from "react-router-dom";
import { IPremise } from "../../types/estateTypes";
import { ISkobaEntityNamed } from "../../../common/types/skobaTypes";

export interface ISpecificItemInfoProps{
    data : ISkobaEntityNamed
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function PremiseInfo(props : ISpecificItemInfoProps){
    const navigate = useNavigate();
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, [props.refreshProps])
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={[{
            name : "Název", 
            property : "name",            
        },{
            name : "Kód", 
            property : "code",            
        },{
            name : "Adresa", 
            property : "adress",            
        },{
            name : "Plocha [m2]", 
            property : "area",            
        },{
            name : "Xdef [m]", 
            property : "xDef",            
        },{
            name : "Ydef [m]", 
            property : "yDef",            
        },{
            name : "Počet budov", 
            property : "buildingsCount",            
        },{
            name : "Počet prostorů", 
            property : "roomsCount",            
        },{
            name : "Plocha prostorů [m2]", 
            property : "roomsArea",            
        },{
            name : "Počet prvků", 
            property : "itemsCount",            
        }]}
        buttons={(object) => [
            <FormModal                       
            key={1}                      
            title="Upravit areál"    
            buttonElement={<span>Upravit</span>}
            form={<PremiseForm buttonText="Uložit změny" model={props.data as IPremise} onSubmit={EstateService.updatePremise}/>}
        /> ,
        <DeleteModal key={2} buttonText="Smazat" itemName={data.name} 
        onConfirm={() => EstateService.deletePremise(props.data.id).then(() => {navigate("/estate/premises")})}/>
        ]}
    />
}