import ApiService from "../../common/services/ApiService";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import { IProcess, IProcessCreate } from "../types/processTypes";

const deleteProcess = (item: IProcess) : Promise<void> => {
    return ApiService.apiDelete("/Processes/" + item.id);
};
const deleteProcessRepeating = (type : number, id : number, date : string) : Promise<void> => {
    return ApiService.apiDelete("/Processes/" + type + "/" + id + "/" + date);
};
const updateProcess = (item: IProcessCreate) : Promise<void> => {
    return ApiService.apiPut("/Processes", item);
}
const updateRepeatingProcess = (type: number, item: IProcessCreate) : Promise<void> => {
    return ApiService.apiPut("/Processes/" + type, item);
}
const createProcess = (item: IProcessCreate) : Promise<IProcess> => {
    return ApiService.apiPost("/Processes", item);
}
const finishProcess = (item: IProcess) : Promise<void> => {
    return ApiService.apiPut("/Processes/Finish", item);
}
const getToDate = (type: number, categoryType: CategoryType | undefined) : Promise<IProcess[]> => {
    if(categoryType === undefined)
        return ApiService.apiGet("/Processes/toDate/" + type);
    return ApiService.apiGet("/Processes/byCategoryType/" + categoryType + "/toDate/" + type);
}
const getAll = () : Promise<IProcess[]> => {
    return ApiService.apiGet("/Processes");
}
const getToDateOfParent = (type: number, parentType: SkobaEntityType, parentId: number, categoryType: CategoryType | undefined) : Promise<IProcess[]> => {
    if(categoryType === undefined)
        return ApiService.apiGet("/Processes/" + parentType + "/" + parentId  + "/toDate/" + type);
    return ApiService.apiGet("/Processes/byCategoryType/" + categoryType + "/" + parentType + "/" + parentId  + "/toDate/" + type);
}
const getAllOfParent = (parentType: SkobaEntityType, parentId: number, categoryType: CategoryType | undefined) : Promise<any> => {
    if(categoryType === undefined)
        return ApiService.apiGet("/Processes/allOfParent?type=" + parentType + "&id=" + parentId);
    return ApiService.apiGet("/Processes/byCategoryType/" + categoryType + "/allOfParent?type=" + parentType + "&id=" + parentId);
};
const ProcessService = {
    deleteProcess,
    createProcess,
    deleteProcessRepeating,
    updateRepeatingProcess,
    updateProcess,
    finishProcess,
    getAll,
    getToDate,
    getToDateOfParent,
    getAllOfParent,
};

export default ProcessService;