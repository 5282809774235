import { data } from "jquery";
import EstateService from "../../services/EstateService";
import { IBuilding } from "../../types/estateTypes";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import FormModal from "../../../categories/components/modals/FormModal";
import BuildingForm from "../forms/BuildingForm";
import { useEffect } from "react";
import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";
import { useNavigate } from "react-router-dom";

export interface ISpecificItemInfoProps{
    data : IBuilding
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function BuildingInfo(props : ISpecificItemInfoProps){
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, props.refreshProps)
    
    const navigate = useNavigate();
    
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={[{
            name : "Název", 
            property : "name",            
        },{
            name : "Kód", 
            property : "code",            
        },{
            name : "Adresa", 
            property : "adress",            
        },{
            name : "Plocha [m2]", 
            property : "area",            
        },{
            name : "Obestavěný prostor [m3]", 
            property : "enclosedArea",            
        },{
            name : "Katastrální území", 
            property : "cadastralTerritory",            
        },{
            name : "Areál", 
            property : "premiseName",
            onClick: (object) => navigate("/estate/premises/" + object.premiseId)         
        },{
            name : "Xdef [m]", 
            property : "xDef",            
        },{
            name : "Ydef [m]", 
            property : "yDef",            
        },{
            name : "Počet prostorů", 
            property : "roomsCount",            
        },{
            name : "Plocha prostorů [m2]", 
            property : "roomsArea",            
        },{
            name : "Počet prvků", 
            property : "itemsCount",            
        }]}
        buttons={(object) => [
            <FormModal 
                key={1} 
                buttonElement={<span>Upravit</span>}
                title="Upravit budovu"    
                form={<BuildingForm parent={props.data.premiseId ? props.data.premiseId : null} 
                    buttonText="Uložit změny" 
                    model={props.data} 
                    selectable={true} 
                    onSubmit={EstateService.updateBuilding}/>}
            /> ,
            <DeleteModal key={2} buttonText="Smazat" 
                itemName={data.name} 
                onConfirm={() => EstateService.deleteBuilding(props.data)
                    .then(() => navigate("/estate/buildings"))}/>
        ]}
    />
}