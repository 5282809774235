import ApiService from "../../common/services/ApiService";
import { IChangePassword, IForgottenPassword, IResetPassword, ISignInResponse, ISignin, ISignup } from "../types/authTypes";
import TokenService from "./TokenService";
import api from "../../common/services/AxiosService";

// const register = (username : string, email : string, password : string) => {
//   return api.instanceHierarchy.post("/auth/signup", {
//     username,
//     email,
//     password
//   });
// };

const signin = async (email : string, password : string) : Promise<ISignInResponse | null> => {
  const dto = {email : email, password : password} as ISignin
  const response = await api.instanceHierarchy
    .post("/auth/signin", JSON.stringify(dto))
    .then((res) => {      
      TokenService.setUser(res.data["data"])
      return res.data})
    .catch((ex) => {
      console.error(ex);
      return null;
    });
  return response;
};

const signup = async (dto : ISignup ) : Promise<any> => {
  const response = await api.instanceHierarchy
    .post("/auth/signup", JSON.stringify(dto))
    .then((res) => {
      console.log(res.data["data"])
      return res.data;
    })
    .catch((ex) => {
      return ex.response.data;
    });
  return response;
};

const changePassword = async (dto : IChangePassword) : Promise<boolean> => {
  const response = await api.instanceHierarchy
    .post("/auth/changePassword", JSON.stringify(dto))
    .then((res) => {
      console.log(res.data["data"])
      return true;
    })
    .catch((ex) => {
      console.error(ex);
      return false;
    });
  return response;
}
const forgottenPassword = async (dto : IForgottenPassword) : Promise<boolean> => {
  const response = await api.instanceHierarchy
    .post("/auth/requestPasswordReset", JSON.stringify(dto))
    .then((res) => {
      console.log(res.data["data"])
      return true;
    })
    .catch((ex) => {
      console.error(ex);
      return false;
    });
  return response;
}
const resetPassword = async (dto : IResetPassword) : Promise<boolean> => {
  const response = await api.instanceHierarchy
    .post("/auth/resetPassword", JSON.stringify(dto))
    .then((res) => {
      console.log(res.data["data"])
      return true;
    })
    .catch((ex) => {
      console.error(ex);
      return false;
    });
  return response;
}
const isTokenValid = async () : Promise<boolean> => {
  const response = await api.instanceHierarchy
    .get("/auth/tokenValid")
    .then((res) => {
      return true;
    })
    .catch((ex) => {
      console.error(ex);
      return false;
    });
  return response;
}

const signout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () : ISignInResponse | null => {
  return TokenService.getUser()
}
const getUserInfo = () : Promise<ISignInResponse> => {
  return ApiService.apiGet("auth/getUserInfo");
}

const AuthService = {
  signin,
  signup,
  signout,
  getCurrentUser,
  changePassword,
  forgottenPassword,
  resetPassword,
  getUserInfo,
  isTokenValid
};

export default AuthService;