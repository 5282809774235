import { Box, Checkbox, FormControlLabel, Grid, Slider, TextField, Typography } from "@mui/material"
import { IItemPropertyForFilter } from "../../types/itemTypes"
import { useEffect, useState } from "react";

export interface IItemFilterFieldProps{
    property: IItemPropertyForFilter
}

export default function ItemFilterField(props: IItemFilterFieldProps){
    const [sliderValue, setSliderValue] = useState<number[]>([10, 100]);
    const [value, setValue] = useState("");
    useEffect(() => {
        if(props.property.dataType === "Celé číslo" || props.property.dataType === "Poměr (procenta)" || props.property.dataType === "Fyzikální veličina (s jednotkou)"){
            const min = props.property.minValue == null ? 0 : props.property.minValue;
            const max = props.property.maxValue == null ? 0 : props.property.maxValue;
            setSliderValue([min, max])
        }
    }, [])
    switch(props.property.dataType){
        case "Celé číslo":
        case "Poměr (procenta)":
        case "Fyzikální veličina (s jednotkou)":
            return <Box>
                <Typography gutterBottom>
                    {props.property.name}
                </Typography>
                <Grid container spacing={2}>
                <Grid item xs={2}>
                    {sliderValue[0]}
                </Grid>
                <Grid item xs={8}>
                    <Slider
                        disabled={props.property.minValue == props.property.maxValue}
                        value={sliderValue}
                        onChange={(event: Event, newValue: number | number[]) => setSliderValue(newValue as number[])}
                        valueLabelDisplay="auto"
                        min={props.property.minValue}
                        max={props.property.maxValue}
                    />
                </Grid>
                <Grid item xs={2}>
                    {sliderValue[1]}
                </Grid>
                </Grid>
            </Box>
        case "Textová hodnota (výčet: CZ_CrossSectionType)":
        case "Textová hodnota":
        case "Textová hodnota (výčet: CZ_RetainingWallType)":
            return <TextField                
                fullWidth
                id="name"
                name="name"
                label={props.property.name}
                size="small"
            />
        case "IfcBoolean":
            return <FormControlLabel
                value="top"
                control={<Checkbox />}
                label={props.property.name}
                labelPlacement="top"
            />
        default:
            return <div></div>
    }
}