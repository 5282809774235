import { Typography } from "@mui/material";
import { ResponsiveContainer, Bar, BarChart, Legend, Tooltip, XAxis, YAxis, Label } from "recharts";

export interface ITimelineChartProps{
    data: any;
    dataKeys: string[];
    title: string
}


const COLORS = ['#586994', '#7D869C', '#A2ABAB', '#B4C4AE', '#E5E8B6'];

export default function TimelineChart(props : ITimelineChartProps){
    return <div style={{ width: '100%', height: '100%' }}>
        <Typography sx={{
            position: "absolute",            
            top: "0",
            right: "50%",
            transform: "translate(50%, 0)",
            fontWeight: "bold",
            zIndex: 50
            }}>
            {props.title}
        </Typography>
		<ResponsiveContainer>
            <BarChart
                title="tetsgrgsegsdfgese"
                data={props.data}
                stackOffset="sign"
                margin={{
                    top: 10,
                    right: 10,
                    left: 30,
                    bottom: 50,
                }}
                >
                <XAxis dataKey="date" label={<Label value="Datum" dy={50} dx={-50}/>}/>
                <YAxis allowDecimals={false} label={<Label value="Počet" dx={-20}/>}/>
                <Tooltip />
                <Legend />
                {props.dataKeys.map((x, i) => <Bar key={i} dataKey={x} fill={COLORS[i%5]} stackId="stack" />)}
            </BarChart>
		</ResponsiveContainer>
	</div>
}