import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowType } from "../layouts/OverviewSplitManager";
import { SideWindowContext } from "../contexts/SideWindowContext";
import BuildingsHierarchy from "../components/content/BuildingsHierarchy";
import EstateService from "../services/EstateService";
import { IVectorData, IVectorLayer } from "../components/maps/ControlMap";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { getPremiseStyle } from "../components/maps/ControlMapStyles";
import PremiseInfo from "../components/tables/PremiseInfo";
import ProcessesHierarchy from "../components/content/ProcessesHierarchy";
import FilesHierarchy from "../components/content/FilesHierarchy";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import { IPremiseDetailed } from "../types/estateTypes";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import RoomsHierarchy from "../components/content/RoomsHierarchy";
import ItemsHierarchy from "../../items/components/content/ItemsHierarchy";
import ItemService from "../../items/services/ItemService";
import FileService from "../../files/services/FileService";
import PrintableWrapper from "../../common/components/PrintableWrapper";


export default function PremisePage(){    
    const { changeSideWindow, openSideWindow, openHierarchy, controlMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IPremiseDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        openSideWindow();
        changeSideWindow(SideWindowType.ControlMap)
    }, [])

    useEffect(() => {
        zoomAndShow()
    }, [data])
    function loadData(){
        EstateService.getHierarchyData("Premises/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function zoomAndShow() : void{        
        if(data && data.xDef && data.yDef){
            const vectorLayers = [] as IVectorLayer[];
            const features = [] as IVectorData[];
            features.push({
                feature: new Feature({
                    geometry: new Point([data.xDef as number,data.yDef as number]),
                    type: "Premise",
                    name: data.name,
                    id: data.id

                }),
                onClick: () => navigate("/estate/premises/" + data.id),
                onLeave: () => {console.log("leave")},
                onEnter: () => {console.log("enter")},
            } as IVectorData )      
            vectorLayers.push({layers: features, style: getPremiseStyle()});
            controlMap?.setVectorData(vectorLayers);
            controlMap?.zoomOnPoint({center: [data.xDef, data.yDef], zoom: 14})
        }
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Premise} 
                tabTitles={["Informace", "Budovy", "Prostory", "Prvky", "Revize", "Činnosti", "Soubory"]} >
                    <PrintableWrapper>
                        <PremiseInfo data={data!} onMount={() => {
                            changeSideWindow(SideWindowType.ControlMap);
                            openSideWindow();
                            zoomAndShow();
                        }}
                        onRefresh={() => {
                            changeSideWindow(SideWindowType.ControlMap);
                            openSideWindow();
                            zoomAndShow();
                        }}
                        refreshProps={[data, controlMap]}
                        />
                    </PrintableWrapper>
                    <BuildingsHierarchy 
                        keepZoom
                        parentId={data!.id} 
                        getData={() => EstateService.getAllOfParent(SkobaEntityType.Premise, data.id, SkobaEntityType.Building)}/>
                    <RoomsHierarchy
                        showLocalMap
                        premiseOption={{id: data.id, name: data.name}}
                        parent={{id: data.id, name: data.name}}
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Premise, data.id, SkobaEntityType.Room)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Premise}}
                        getData={async (category: CategoryType, subCategory: CategoryType) => ItemService.getAllOfParent(SkobaEntityType.Premise, data.id, category, subCategory)}/>
                    <ProcessesHierarchy
                        category={CategoryType.Revision}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Premise}}/>
                    <ProcessesHierarchy
                        category={CategoryType.Activity}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Premise}}/>
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Premise, data.id)}
                        parent={{name: data!.name, id: data!.id, type: SkobaEntityType.Premise}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }