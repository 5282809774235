import Sidebar, { LargeIconSize } from "../../common/components/Sidebar"
import STitle from "../../common/components/text/STitle"
import { useLocation } from "react-router-dom"
import { SkobaFilesIcon } from "../../common/components/icons/SkobaIcons"
import { SkobaColors } from "../../common/types/SkobaTheme"

const links = [
    // {
    //     text: "Přehled",
    //     to: "/processes",
    //     icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    // },
    {
        text: "Revize",
        to: "/processes/revisions",
        icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Činnosti",
        to: "/processes/activities",
        icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Druhy revizí",
        to: "/processes/revisions/categories",
        icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Typy činností",
        to: "/processes/activities/categories",
        icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    }
]

export default function ProcessesSidebar(){
    const location = useLocation();
    return <Sidebar
    header={<STitle text="" />}
    items={links}
    getActive={(item) => location.pathname == item.to}
    color={SkobaColors.Gray2}
    toggle
/>
}