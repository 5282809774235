import { PropsWithChildren } from "react";
import React from "react";
import { Box } from "@mui/material";
import TabLayout from "../../common/layouts/TabLayout";
import STitle from "../../common/components/text/STitle";
import { SkobaEntityType } from "../../common/types/skobaTypes";
import HierarchyHeader from "../../common/components/hierarchy/HierarchyHeader";

export interface IHierarchySingleProps{
    tabTitles: string[];
    title: string;
    id: number;
    type: SkobaEntityType;
}

export default function HierarchySinglePageNew(props : PropsWithChildren<IHierarchySingleProps>) {
    return <Box sx={{p:1, overflowY: "auto", height:"100%", '& > *': { mb: 1 }, overflow: "auto"}}>
        <HierarchyHeader title={props.title} id={props.id} type={props.type}/>
        <TabLayout queryPrefix="hsp" titles={props.tabTitles}>
            {props.children}
        </TabLayout>                  
    </Box> 
}