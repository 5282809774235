import ActivitiesPage from "./ActivitiesPage";
import RevisionCategoriesPage from "./RevisionCategoriesPage";
import ProcessesPage from "./ProcessesPage";
import ProcessPage from "./ProcessPage";
import RevisionsPage from "./RevisionsPage";
import ActivityCategoriesPage from "./ActivityCategoriesPage";

const ProcessesIndex = [  
    // {
    //   path: "",
    //   element: <ProcessesPage/>
    // },  
    {
      path: "activities",
      element: <ActivitiesPage/>
    },  
    {
      path: "revisions",
      element: <RevisionsPage/>
    },  
    {
      path: ":id",
      element: <ProcessPage/>
    },
    {
      path: "activities/categories",
      element: <ActivityCategoriesPage/>
    },
    {
      path: "revisions/categories",
      element: <RevisionCategoriesPage/>
    },

]
export default ProcessesIndex;