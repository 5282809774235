import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";

export interface ISummaryProps{
    data: any[];
}

export default function ItemsSummary(props: ISummaryProps){
    const data = {
        count: props.data.length,
    }
    return <PropertiesInfoTable
        data={data}
        rowDefinition={[{
            name : "Počet prvků", 
            property : "count",            
        }]}           
    />
}