import { getConnectorParentRoute } from "../../../common/Functions";
import { useNavigate } from "react-router-dom";
import SkobaTable, { ISkobaTableDataProps } from "../../../common/components/tables/SkobaTable";

const ItemsHeader = ["Název", "Typ", "Podtyp", "Položka"];

export default function ItemsTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={ItemsHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                        property: "name",
                        onClick: (object) => navigate("/items/" + object.id)
                    },{
                        property: "definitionItemTypeName"
                    },{
                        property: "definitionItemTypeParentTypeName"
                    },{
                        property: "locationName",
                        onClick: (object) => navigate(getConnectorParentRoute(object))
                    }
                ],
                
            }
        }
    />
}