import React, { useContext, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { SideWindowContext } from "../contexts/SideWindowContext";
import { getBuildingStyle, getPremiseStyle } from "../components/maps/ControlMapStyles";
import { IVectorLayer, IVectorData } from "../components/maps/ControlMap";
import { IOverview } from "../types/estateTypes";
import { SideWindowType } from "../layouts/OverviewSplitManager";




export default function OverviewPage(){
    const data = useLoaderData() as IOverview[];
    const { changeSideWindow, closeHierarchy, chartObj, controlMap, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    useEffect(() => {
        closeHierarchy();
        changeSideWindow(SideWindowType.ControlMap)
    }, [])
    useEffect(() => {
        const vectorLayers = [] as IVectorLayer[];
        const buildings = data.filter(x=>x.type === 0 && x.xDef && x.yDef).map(x =>
            {
                return {
                    feature: new Feature({
                        geometry: new Point([x.xDef as number,x.yDef as number]),
                        type: "Building",
                        name: x.name

                    }),
                    onClick: () => navigate("/estate/buildings/" + x.id),
                    onLeave: () => {console.log("leave")},
                    onEnter: () => {console.log("enter")}
                } as IVectorData
            }
        
        );        
        vectorLayers.push({layers: buildings, style: getBuildingStyle()});
        const premises = data.filter(x=>x.type === 1 && x.xDef && x.yDef).map(x =>
            {
                return {
                    feature: new Feature({
                        geometry: new Point([x.xDef as number,x.yDef as number]),
                        type: "Premise",
                        name: x.name

                    }),
                    onClick: () => navigate("/estate/premises/" + x.id),
                    onLeave: () => {console.log("leave")},
                    onEnter: () => {console.log("enter")}
                } as IVectorData
            }
        
        );        
        vectorLayers.push({layers: premises, style: getPremiseStyle()});
        controlMap?.setVectorData(vectorLayers);
    }, [data, controlMap, localMap, chartObj])
    return <React.Fragment key={data.length}>

    </React.Fragment>
    
}