import { Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import OrganizationInfo from "../components/content/OrganizationInfo";
import TabLayout from "../../common/layouts/TabLayout";
import STitle from "../../common/components/text/STitle";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import SubscriptionForm from "../components/forms/SubscriptionForm";
import AdminService from "../services/AdminService";
import { IOrganization, ISubscription } from "../types/adminTypes";
import FormModal from "../../categories/components/modals/FormModal";

export default function InfoPage(){
    const [loadedOrg, setLoadedOrg] = useState(false);    
    const [loadedSub, setLoadedSub] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [organization, setOrganization] = useState<IOrganization>();
    const [subscription, setSubscription] = useState<ISubscription>();
    
    useEffect(() => getData(), [])
    function getData(){
        AdminService.getOrganizationInfo()
        .then(
            (result) => {
                setLoadedOrg(true);
                setOrganization(result as IOrganization);
            },
            (error) => {
                setLoadedOrg(true);
                setError(error);
            }
        )
        // AdminService.getLatestSubscription()
        // .then(
        //     (result) => {
                setLoadedSub(true);
        //         setSubscription(result as ISubscription);
        //         // setSubscription(null as ISubscription)
        //     },
        //     (error) => {
        //         setLoadedSub(true);
        //         setError(error);
        //     }
        // )
    }
    function getLatestSubscriptionInfo() : JSX.Element{
        if(subscription == null){
            return <React.Fragment>
                <Typography
                variant="h6"
                color="inherit">
                    Žádná předplatné není aktivní
                </Typography>
                <FormModal
                        needsAdmin={true}
                    form={<SubscriptionForm/>}
                    title="Vyberte si předpatné"   
                    buttonText="Koupit"                 
                />
            </React.Fragment>
        }
        else if(subscription.dateOfPayment !== "" && subscription.dateOfPayment != null){
            return <React.Fragment>
                <ul>
                    <li>Zaplaceno dne: {subscription.dateOfPayment}</li> 
                    <li>Zaplaceno do: {subscription.end}</li>
                </ul>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormModal
                        needsAdmin={true}
                        form={<SubscriptionForm/>}
                        title="Prodloužit předplatné"   
                        buttonText="Prodloužit"                 
                        />
                    <FormModal
                        needsAdmin={true}
                        form={<SubscriptionForm/>}
                        title="Upgradovat předplatné"   
                        buttonText="Upgradovat"                 
                        />
                </Box>
            </React.Fragment>
        }
        else{
            return <React.Fragment>
                <Typography
                    variant="h6"
                    color="inherit">
                        Aktuální předpatné není zaplaceno
                </Typography>
                <FormModal                 
                    needsAdmin={true}
                    form={<SubscriptionForm/>}
                    title="Vyberte si předpatné"   
                    buttonText="Zaplatit"                 
                />
            </React.Fragment>
        }
        
    }
    if (error)
        return <div>Nastala chyba: {error.message}</div>;
    else if (!loadedOrg || !loadedSub)
        return <div>Načítání...</div>;
    else {
        return (<React.Fragment>
            <RefreshDataContext.Provider value={getData}>
                <Box sx={{p:2}}>
                    <STitle text="Přehled"/>
                    <TabLayout queryPrefix="ip" titles={["Informace", "Předplatné"]}>
                        <OrganizationInfo data={organization!}/>
                        {getLatestSubscriptionInfo()}
                    </TabLayout>
                </Box>
            </RefreshDataContext.Provider>            
        </React.Fragment>
    )}
}