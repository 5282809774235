import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowContext } from "../../estate/contexts/SideWindowContext";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import ItemService from "../services/ItemService";
import { IItem, IItemDetailed, IItemProperty, IPropertyValue } from "../types/itemTypes";
import ItemInfo from "../components/tables/ItemInfo";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import FilesHierarchy from "../../estate/components/content/FilesHierarchy";
import ProcessesHierarchy from "../../estate/components/content/ProcessesHierarchy";
import FileService from "../../files/services/FileService";
import ProcessService from "../../processes/services/ProcessService";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import PrintableWrapper from "../../common/components/PrintableWrapper";


export default function ItemPage(){    
    const { closeSideWindow, openHierarchy } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IItemDetailed>();
    const [properties, setProperties] = useState<IItemProperty[]>();
    const [values, setValues] = useState<IPropertyValue[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        closeSideWindow();
    }, [])
    function loadData(){
        const propertiesPromise = ItemService.getItemProperties(id);
        const valuesPromise = ItemService.getItemValues(id);
        const dataPromise = ItemService.getItem(id);
        Promise.all([propertiesPromise, dataPromise, valuesPromise])
        .then(
            ([data1, data2, data3]) => {
                setLoading(false);
                setProperties(data1);
                setData(data2);
                setValues(data3);
            },
            (error) => {
                setLoading(false);
                setError(error);
                setError(error);
            }
        )
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Item} 
                tabTitles={["Informace", "Revize", "Činnosti", "Soubory"]} >
                    <PrintableWrapper>                        
                        <ItemInfo data={data} properties={properties!} values={values!}
                        />
                    </PrintableWrapper>
                    <ProcessesHierarchy
                        category={CategoryType.Revision}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Item}}/>
                    <ProcessesHierarchy
                        category={CategoryType.Activity}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Item}}/>
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Item, data.id)}
                        parent={{name: data!.name, id: data!.id, type: SkobaEntityType.Item}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }