import React from "react";
import Sidebar, { ISidebarItem } from "./Sidebar";
import { useLocation } from "react-router-dom";
import { SkobaLogo } from "./icons/SkobaIcons";
import { SkobaColors } from "../types/SkobaTheme";
import UserInfo from "../../auth/components/content/UserInfo";

const links = [
    {
      to : "/estate/overview",
      text : "Lokace",
    },
    {
      to : "/items",
      text : "Prvky",
    },
    {
      to : "/processes",
      text : "Události",
    },
    {
      to : "/files",
      text : "Soubory",
    },
    {
      to : "/admin",
      text : "Administrace",
    },
    // {
    //   to : "/adamStarter",
    //   text : "Adam",
    // }
] as ISidebarItem[]

export default function SkobaSidebar() {
    const location = useLocation();

    return <Sidebar
        header={<React.Fragment>
          <SkobaLogo sx={{height: "60px", width:"100%"}}/>
          <UserInfo/>
        </React.Fragment>}
        items={links}
        getActive={(item) => location.pathname.includes(item.to)}
        color={SkobaColors.Gray1}
    />
}