import React, { useContext } from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { CloseFormContext } from '../../../common/contexts/CloseFormContext';
import { RefreshDataContext } from '../../../common/contexts/RefreshDataContext';
import { IPremise } from "../../types/estateTypes";
 
 const PremiseValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Název nemůže být prázdný'),
    code: Yup.string()
        .max(50, 'Too Long!')
        .required('Kód nemůže být prázdný'),
    adress: Yup.string()
        .max(50, 'Too Long!')
        .nullable(),
    area: Yup.number()
        .positive()
        .nullable(),
    xDef: Yup.number()
        .nullable(),
    yDef: Yup.number()
        .nullable()
        
 });

 export interface IPremiseFormProps{
    model: IPremise | null;
    buttonText : string;
    onSubmit: (values : any) => Promise<any>;
}

export default function PremiseForm(props : IPremiseFormProps) {  
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext);
  
    const [error, setError] = React.useState("");
    const [submitting, setSubmit] = React.useState(false);
    const handleSubmit = async (data : any) => {
        await props.onSubmit(data)
        .then((res) =>{
            setSubmit(false);
            handleClose();
            handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
      })
    };
    const formik = useFormik({
        initialValues: props.model == null ? {
            name: '',
            code: '',
            adress: '',
            area: '',
            xDef: '',
            yDef: '',
        } : 
        {            
            id: props.model.id,
            name: props.model.name,
            code: props.model.code,
            adress: props.model.adress,
            area: props.model.area,
            xDef: props.model.xDef,
            yDef: props.model.yDef,
        },
        validationSchema: PremiseValidationSchema,
        onSubmit: handleSubmit,
    });
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
        <TextField                
            fullWidth
            id="name"
            name="name"
            label="Název"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={submitting}
        />
        <TextField
            fullWidth
            id="code"
            name="code"
            label="Kód"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            disabled={submitting}
        />
        <TextField
            fullWidth
            id="adress"
            name="adress"
            label="Adresa"
            value={formik.values.adress}
            onChange={formik.handleChange}
            error={formik.touched.adress && Boolean(formik.errors.adress)}
            helperText={formik.touched.adress && formik.errors.adress}
            disabled={submitting}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
                id="area"
                name="area"
                label="Plocha"
                type="number"
                value={formik.values.area}
                onChange={formik.handleChange}
                error={formik.touched.area && Boolean(formik.errors.area)}
                helperText={formik.touched.area && formik.errors.area}
                sx={{ marginRight: 1 }}
                disabled={submitting}
            />
            <TextField
                id="xDef"
                name="xDef"
                label="xDef"
                type="number"
                value={formik.values.xDef}
                onChange={formik.handleChange}
                error={formik.touched.xDef && Boolean(formik.errors.xDef)}
                helperText={formik.touched.xDef && formik.errors.xDef}
                sx={{ marginRight: 1 }}
                disabled={submitting}
            />
            <TextField
                id="yDef"
                name="yDef"
                label="yDef"
                type="number"
                value={formik.values.yDef}
                onChange={formik.handleChange}
                error={formik.touched.yDef && Boolean(formik.errors.yDef)}
                helperText={formik.touched.yDef && formik.errors.yDef}
                disabled={submitting}
            />
        </Box>      
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
            {props.buttonText}
        </Button>
        {error !== "" && 
            <Typography component="i" color="red">
            Při zpracovávání vašeho požadavku došlo k chybě: {error}
            </Typography>                  
        }
    </Box>
    </div>    
}