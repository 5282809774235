import React, { ReactElement, ReactNode } from "react";

import { Navigate } from "react-router-dom";
import AuthService from "../../auth/services/AuthService";

const OWNER_ROLE = "Owner"
const ADMIN_ROLE = "Administrator"
const EDITOR_ROLE = "Editor"
const USER_ROLE = "User"

interface LayoutType{
    children:  ReactNode | ReactElement | JSX.Element;

}

const CheckForRole = (userRole : string, requiredRoles : string[]) : boolean =>{
    return requiredRoles.findIndex((role) => role === userRole) === -1;
}
export const PublicLayout = ({ children }: LayoutType) : ReactElement => {
    return <React.Fragment>
        {children}
    </React.Fragment>
};  

export const ProtectedLayout = ({ children }: LayoutType) : ReactElement => {
    const user = AuthService.getCurrentUser();
    if(user === null)
        return <Navigate to="/signin" />
    return <React.Fragment>
        {children}
    </React.Fragment>
}; 

export const DevLayout = ({ children }: LayoutType) : ReactElement => {
    if(process.env.REACT_APP_IS_DEV === "true")
        return <React.Fragment>
            {children}
        </React.Fragment>
    return <React.Fragment/>
}; 

export const EditorLayout = ({ children }: LayoutType) : ReactElement => {
    const user = AuthService.getCurrentUser();
    if(user === null)
        return <Navigate to="/signin" />
    if(CheckForRole(user.roles, [OWNER_ROLE, ADMIN_ROLE, EDITOR_ROLE]) || !user.paidUser){
        return <React.Fragment/>
    }
    return <React.Fragment>
        {children}
    </React.Fragment>
};
export const AdminLayout = ({ children }: LayoutType) : ReactElement => {
    const user = AuthService.getCurrentUser();
    if(user === null)
        return <Navigate to="/signin" />
    if(CheckForRole(user.roles, [OWNER_ROLE, ADMIN_ROLE])){
        return <React.Fragment/>
    }
    return <React.Fragment>
        {children}
    </React.Fragment>
};

export const OwnerLayout = ({ children }: LayoutType) : ReactElement => {
    const user = AuthService.getCurrentUser();
    if(user === null)
        return <Navigate to="/signin" />
    if(CheckForRole(user.roles, [OWNER_ROLE])){
        return <React.Fragment/>
    }
    return <React.Fragment>
        {children}
    </React.Fragment>
};  


